import { gql } from "@apollo/client";

export default gql`
  query searchMeetingsQuery(
    $search: String
    $startDatetimeGte: DateTime
    $excludeMeetingGroupIds: [Int!]
  ) {
    meetings(
      search: $search
      first: 30
      draft: false
      status_In: [confirmed, tentative]
      ignored: false
      excludeMeetingGroupIds: $excludeMeetingGroupIds
      startDatetime_Gte: $startDatetimeGte
      orderBy: "start_datetime"
    ) {
      edges {
        node {
          id
          title
          startDatetime
          meetingGroup {
            id
            title
          }
        }
      }
    }
  }
`;
