import { XCircleIcon } from "@heroicons/react/solid";

import { classNames } from "@helpers/css";

const AppError = ({
  title = "An unexpected error occurred.",
  description = "",
  mini = false,
  className = "",
}: {
  title?: string;
  description?: string;
  mini?: boolean;
  className?: string;
}) =>
  mini ? (
    <div className={classNames("text-red-700", className)}>
      {description || title}
    </div>
  ) : (
    <div
      className={classNames(
        "rounded-md bg-red-50 p-2 border border-red-600 border-opacity-20 text-sm",
        className
      )}
      data-testid="error"
    >
      <div className="flex text-red-800">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="font-medium">{title}</h3>
          {description && (
            <div className="mt-2 text-xs leading-normal">{description}</div>
          )}
        </div>
      </div>
    </div>
  );

export default AppError;
