import { SelectorIcon, XIcon } from "@heroicons/react/outline";
import { isEqual, pick } from "lodash";
import { ButtonHTMLAttributes, forwardRef } from "react";
import { Maybe } from "types/graphql-schema";

import { classNames } from "@helpers/css";

import { ExplorerFilterType } from "../helpers";

const selectClassName = classNames(
  "bg-white text-gray-700 hover:text-gray-800 border border-gray-300 shadow-sm",
  "rounded text-left cursor-default text-sm tracking-tight",
  "flex-1 pl-2 pr-1 py-1 flex items-center gap-1"
);

const flattenOptions = (
  options: ExplorerMenuButtonOption[],
  memo: ExplorerMenuButtonOption[]
): ExplorerMenuButtonOption[] => {
  return options.reduce((subMemo, option) => {
    if (option.submenu) return flattenOptions(option.submenu, subMemo);
    return subMemo.concat(option);
  }, memo as ExplorerMenuButtonOption[]);
};

export type ExplorerMenuButtonOption = {
  value?: any;
  label?: Maybe<string>;
  selectedLabel?: Maybe<string>;
  filters: Partial<ExplorerFilterType>;
  selected?: boolean;
  description?: string;
  onClick?: any;
  submenu?: ExplorerMenuButtonOption[];
};

type Props = Omit<ButtonHTMLAttributes<HTMLButtonElement>, "d"> & {
  prefix: string;
  options: ExplorerMenuButtonOption[];
  filters: Partial<ExplorerFilterType>;
  filterKey: keyof ExplorerFilterType;
  multiple?: boolean;
  onClickRemove?: (filterKey: keyof ExplorerFilterType) => void;
};

const ExplorerMenuButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      prefix,
      options,
      filters,
      filterKey,
      multiple = false,
      onClickRemove,
      ...props
    },
    ref
  ) => {
    const allFlattenOptions = flattenOptions(options, []);
    const firstOption = allFlattenOptions[0];
    let displayedOptionLabel = firstOption.selectedLabel || firstOption.label;
    const value = filters[filterKey];
    if (multiple && filterKey && Array.isArray(value)) {
      const selectedOptions = allFlattenOptions.filter((option) => {
        return (
          option.value !== undefined &&
          option.value !== null &&
          value.includes(option.value)
        );
      });
      displayedOptionLabel = selectedOptions
        .map((option) => option.selectedLabel || option.label)
        .join(", ");
    } else {
      const selectedOption = allFlattenOptions.find((option) => {
        const pickedFilters = pick(filters, Object.keys(option.filters));
        return isEqual(option.filters, pickedFilters);
      });
      if (selectedOption) {
        displayedOptionLabel =
          selectedOption.selectedLabel || selectedOption.label;
      }
    }

    const handleClickRemove = () => {
      if (onClickRemove) {
        onClickRemove(filterKey);
      }
    };

    return (
      <div className="flex-1 flex items-center w-full">
        <button
          className={classNames(
            selectClassName,
            "min-w-0",
            onClickRemove && "rounded-r-none pr-1"
          )}
          ref={ref}
          {...props}
        >
          {prefix && <span className="font-medium">{prefix}: </span>}
          <div className="flex-1 truncate">{displayedOptionLabel}</div>
          <SelectorIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
        </button>
        {onClickRemove && (
          <button
            onClick={handleClickRemove}
            className={classNames(
              selectClassName,
              "grow-0 py-1.5 px-0.5 rounded-l-none border-l-0 text-gray-400 hover:bg-gray-100"
            )}
            aria-label="Explorer filter remove button"
          >
            <XIcon className="h-4 w-4" />
          </button>
        )}
      </div>
    );
  }
);

export default ExplorerMenuButton;
