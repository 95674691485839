import { useQuery } from "@apollo/client";
import { compact, uniqBy } from "lodash";
import moment from "moment";
import { useState } from "react";

import MeetingCombobox from "@components/meeting-combobox/meeting-combobox";
import useDebounce from "@components/use-debounce/use-debounce";
import { useNotificationError } from "@components/use-error/use-error";
import { delay } from "@helpers/constants";

import searchMeetingsQuery from "../graphql/search-meetings-query";

const unassigned = {
  name: "Pick a meeting...",
  id: null,
};

const FlowMeetingCombobox = ({
  label,
  excludeMeetingGroupIds,
  onChange,
}: {
  label: any;
  excludeMeetingGroupIds: any;
  onChange: any;
}) => {
  // Hooks
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [canLoadData, setCanLoadData] = useState(false);
  const { onError } = useNotificationError();
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, delay.searchDebounce);
  const startDatetimeGte = moment()
    .subtract(30, "days")
    .startOf("day")
    .toISOString();
  const { loading } = useQuery(searchMeetingsQuery, {
    variables: {
      search: debouncedQuery,
      excludeMeetingGroupIds,
      startDatetimeGte:
        debouncedQuery.trim().length > 0 ? null : startDatetimeGte,
    },
    onCompleted: (data) => {
      const newSearchResults =
        data?.meetings.edges.map(({ node }: { node: any }) => node) || [];
      setSearchResults(searchResults.concat(newSearchResults));
    },
    skip: !canLoadData,
    onError,
  });

  // Computed data
  const options = uniqBy(compact(searchResults), "id").filter(
    ({ meetingGroup }) =>
      !!meetingGroup && !excludeMeetingGroupIds.includes(meetingGroup.id)
  );
  const comboboxLoading = loading || query !== debouncedQuery;

  // Handlers
  const handleChangeValue = (newOption: any) => {
    if (newOption.id) {
      onChange(newOption);
    } else {
      onChange(null);
    }
    setQuery("");
  };

  const handleClearValue = () => {
    handleChangeValue(null);
  };

  const handlePreloadData = () => {
    setCanLoadData(true);
  };

  // RENDER
  return (
    <div className="w-full text-sm">
      <MeetingCombobox
        placeholder="Meeting title..."
        portal
        aria-label={`${label} combobox`}
        loading={comboboxLoading}
        width="full"
        query={query}
        value={unassigned}
        options={options}
        clearable={false}
        onChangeValue={handleChangeValue}
        onChangeQuery={setQuery}
        onClickButton={(e) => {
          e.stopPropagation();
          handlePreloadData();
        }}
        onClearValue={handleClearValue}
      />
    </div>
  );
};

export default FlowMeetingCombobox;
