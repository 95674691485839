import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  GetPreviousAssessmentsQuery,
  GetPreviousAssessmentsQueryVariables,
  PreviousAssessmentFragment,
} from "types/graphql-schema";

import getPreviousAssessmentsQuery from "@apps/assessments/graphql/get-previous-assessments-query";
import { currentOrganizationVar, currentUserVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import Loading from "@components/loading/loading";
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableFooter,
  TableHeadCell,
  TableHeadRow,
  TableSortDir,
} from "@components/table/table";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

const PreviousAssessmentRow = ({
  assessment,
  hasResponderField,
}: {
  assessment: PreviousAssessmentFragment;
  hasResponderField?: boolean;
}) => {
  const location = useLocation();
  const target = assertNonNull(assessment.target);
  const responder = assessment.responder;
  const program = assertNonNull(assessment.complianceProgram);
  return (
    <TableBodyRow key={assessment.id} className="h-16">
      <TableBodyCell>
        <div className="flex items-center">
          <Avatar className="mr-2" size="8" user={target} /> {target.name}
          {target.id === responder?.id && " (Self assessment)"}
        </div>
      </TableBodyCell>
      {hasResponderField && (
        <TableBodyCell>
          <div className="flex items-center">
            <Avatar className="mr-2" size="8" user={responder} />{" "}
            {responder?.name ?? "Anonymous"}
          </div>
        </TableBodyCell>
      )}
      <TableBodyCell>{program.title}</TableBodyCell>
      <TableBodyCell>
        {moment(assessment.submittedDatetime).format("MMM D, YYYY")}
      </TableBodyCell>
      <TableBodyCell className="text-right">
        <Button
          theme={buttonTheme.lightBlue}
          to={{
            pathname: `/assessments/assessment/${assessment.id}`,
            state: {
              previousPathname: `${location.pathname}${location.search}`,
            },
          }}
        >
          View
        </Button>
      </TableBodyCell>
    </TableBodyRow>
  );
};

enum SortKey {
  targetName = "target_name",
  responderName = "responder_name",
  submitted = "submitted_datetime",
  program = "compliance_program__title",
}

const MyAssessmentsSubmitted = ({
  userFilter,
}: {
  userFilter: number | null;
}) => {
  const currentUser = assertNonNull(currentUserVar());
  const organization = currentOrganizationVar();
  const [sortKey, setSortKey] = useState<SortKey>(SortKey.submitted);
  const [sortDir, setSortDir] = useState<TableSortDir>("desc");

  const [fetchPreviousAssessments, { data, loading, fetchMore }] = useLazyQuery<
    GetPreviousAssessmentsQuery,
    GetPreviousAssessmentsQueryVariables
  >(getPreviousAssessmentsQuery, {
    onError: onNotificationErrorHandler(),
  });

  useEffect(() => {
    if (userFilter === null) {
      fetchPreviousAssessments({
        variables: {
          organizationId: organization.id,
          responderId: currentUser.id,
          orderBy: `${sortDir === "desc" ? "-" : ""}${sortKey}`,
        },
        onError: onNotificationErrorHandler(),
      });
    } else {
      fetchPreviousAssessments({
        variables: {
          organizationId: organization.id,
          targetId: userFilter,
          orderBy: `${sortDir === "desc" ? "-" : ""}${sortKey}`,
        },
      });
    }
  }, [
    userFilter,
    currentUser,
    fetchPreviousAssessments,
    organization,
    sortDir,
    sortKey,
  ]);

  const handleLoadMore = () => {
    if (data?.assessments.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          merge: true,
          after: data.assessments.pageInfo.endCursor,
        },
      });
    }
  };

  const handleColumnSort = useCallback(
    (newSortKey: SortKey) => {
      if (newSortKey !== sortKey) {
        setSortKey(newSortKey);
      } else {
        setSortDir(sortDir === "asc" ? "desc" : "asc");
      }
    },
    [sortDir, sortKey]
  );

  const previousAssessments = useMemo(
    () => (data ? assertEdgesNonNull(data.assessments) : []),
    [data]
  );

  if (loading) {
    return <Loading className="p-6 w-full mx-auto">Loading</Loading>;
  }

  return (
    <>
      <TableContainer aria-label="Previous assessments">
        <Table>
          <TableHeadRow>
            <TableHeadCell
              sorted={sortKey === SortKey.targetName ? sortDir : undefined}
              onClick={() => handleColumnSort(SortKey.targetName)}
            >
              Subject
            </TableHeadCell>
            <TableHeadCell
              sorted={sortKey === SortKey.responderName ? sortDir : undefined}
              onClick={() => handleColumnSort(SortKey.responderName)}
            >
              Responder
            </TableHeadCell>
            <TableHeadCell
              sorted={sortKey === SortKey.program ? sortDir : undefined}
              onClick={() => handleColumnSort(SortKey.program)}
            >
              Program
            </TableHeadCell>
            <TableHeadCell
              sorted={sortKey === SortKey.submitted ? sortDir : undefined}
              onClick={() => handleColumnSort(SortKey.submitted)}
            >
              Submitted
            </TableHeadCell>
            <TableHeadCell className="w-52"></TableHeadCell>
          </TableHeadRow>
          <TableBody>
            {previousAssessments.map((assessment) => (
              <PreviousAssessmentRow
                hasResponderField
                key={assessment.id}
                assessment={assessment}
              />
            ))}
            {!loading && previousAssessments.length === 0 && (
              <TableBodyRow>
                <TableBodyCell colSpan={5}>
                  No assessments submitted.
                </TableBodyCell>
              </TableBodyRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TableFooter>
        {data?.assessments.pageInfo.hasNextPage && (
          <div className="flex justify-center">
            {loading ? (
              <Loading mini size="5" />
            ) : (
              <button
                onClick={handleLoadMore}
                className="text-gray-500 hover:underline"
              >
                View more
              </button>
            )}
          </div>
        )}
      </TableFooter>
    </>
  );
};

export default MyAssessmentsSubmitted;
