import { range } from "lodash";
import {
  AssessmentQuestionResponseVisibility,
  AssessmentQuestionResponses,
  AssessmentQuestionType,
  AssessmentType,
} from "types/graphql-schema";

import { AssessmentQuestion } from "./components/assessment-question-form";

export const bgClassName = "bg-gray-50 rounded-lg shadow-md";

export const ASSESSMENT_QUESTION_SCALE_MIN = 1;
export const ASSESSMENT_QUESTION_SCALE_MAX = 10;
export const ASSESSMENT_QUESTION_DEFAULT_WEIGHT = 1;

export const getAssessmentQuestionScale = (question: AssessmentQuestion) => {
  const scaleStartValue = Math.max(1, question.startValue ?? 1);
  const scaleEndValue = Math.min(
    ASSESSMENT_QUESTION_SCALE_MAX,
    question.endValue ?? 5
  );
  return {
    scaleEndValue,
    scaleStartValue,
    scaleDiff: scaleEndValue - scaleStartValue + 1,
  };
};

const blankQuestionValueCount = 5;
export const emptyAssessmentQuestion = (
  questionType: AssessmentQuestionType,
  assessmentType?: AssessmentType
) => ({
  title: "",
  description: null,
  startValue: questionType === AssessmentQuestionType.Range ? 1 : undefined,
  endValue:
    questionType === AssessmentQuestionType.Range
      ? blankQuestionValueCount
      : undefined,
  labels:
    questionType === AssessmentQuestionType.Range
      ? range(0, blankQuestionValueCount).map(() => "")
      : undefined,
  labelDescriptions:
    questionType === AssessmentQuestionType.Range
      ? range(0, blankQuestionValueCount).map(() => "")
      : undefined,
  assessmentType: assessmentType ?? AssessmentType.Performance,
  questionType: AssessmentQuestionType.Range,
  isCommentMandatory: false,
  responses:
    assessmentType === AssessmentType.Peer
      ? AssessmentQuestionResponses.ExcludeSelfAssessment
      : AssessmentQuestionResponses.Both,
  responseVisibility: AssessmentQuestionResponseVisibility.All,
  optionCount:
    questionType === AssessmentQuestionType.Multichoice ? 2 : undefined,
  options:
    questionType === AssessmentQuestionType.Multichoice
      ? ["Option 1", "Option 2"]
      : undefined,
  optionDescriptions:
    questionType === AssessmentQuestionType.Multichoice ? ["", ""] : undefined,
  categories: [],
});

export const getValidAssessmentQuestionMessage = (
  proposedQuestion: AssessmentQuestion
) => {
  const optionsMissingValues = range(0, proposedQuestion.optionCount)
    .map((x) => {
      return proposedQuestion.options?.[x] || "";
    })
    .some((opt) => opt?.trim() === "");
  return proposedQuestion.title.trim().length === 0
    ? "Please enter a title "
    : proposedQuestion.questionType === AssessmentQuestionType.Multichoice &&
      optionsMissingValues
    ? "All options must have values"
    : null;
};
