import { useMutation, useQuery } from "@apollo/client";
import { compact, flatMap } from "lodash";
import { useCallback, useMemo, useState } from "react";
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
import { useLocation, useParams } from "react-router-dom";
import {
  AssessmentGroupAnonymity,
  AssessmentGroupDelivery,
  AssessmentGroupProviders,
  AssessmentGroupSectionInput,
  AssessmentQuestionType,
  AssessmentType,
  GetAssessmentGroupQuery,
  GetAssessmentGroupQueryVariables,
  GetAssessmentQuestionsQuery,
  GetAssessmentQuestionsQueryVariables,
  Maybe,
  MultiChoiceAssessmentQuestionNode,
  RangeAssessmentQuestionNode,
  SaveAssessmentGroupMutation,
  SaveAssessmentGroupMutationVariables,
  TextAssessmentQuestionNode,
} from "types/graphql-schema";

import { currentOrganizationVar, successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Input from "@components/input/input";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import Select from "@components/select/select";
import {
  onNotificationErrorHandler,
  useNotificationError,
} from "@components/use-error/use-error";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import {
  assessmentGroupAnonymityOptions,
  assessmentGroupDeliveryOptions,
  assessmentGroupProviderOptions,
  assessmentTypeOptions,
} from "@helpers/constants";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import createOrUpdateAssessmentGroupMutation from "../graphql/create-or-update-assessment-group-mutation";
import getAssessmentGroupQuery from "../graphql/get-assessment-group-query";
import getAssessmentGroupsQuery from "../graphql/get-assessment-groups-query";
import getQuestionsQuery from "../graphql/get-questions-query";
import { ASSESSMENT_QUESTION_DEFAULT_WEIGHT, bgClassName } from "../helpers";
import AssessmentGroupQuestionItem from "./assessment-group-question-item";

const defaultAssessmentGroupSettings = (assessmentType: AssessmentType) => {
  return {
    delivery: AssessmentGroupDelivery.Full,
    anonymity:
      assessmentType === AssessmentType.Manager
        ? AssessmentGroupAnonymity.SemiAnonymous
        : AssessmentGroupAnonymity.NotAnonymous,
    providers: AssessmentGroupProviders.Default,
  };
};

const AssessmentGroupEdit = () => {
  const link = useLink();
  const location = useLocation();
  const organization = currentOrganizationVar();
  const { onError } = useNotificationError();
  const { assessmentGroupId: assessmentGroupIdParam } = useParams<{
    assessmentGroupId: string;
  }>();
  const assessmentGroupId = parseInt(assessmentGroupIdParam);
  const isNew = isNaN(assessmentGroupId);
  const isDuplicate = !isNew && location.pathname.includes("/duplicate");
  const [proposedAssessmentGroup, setProposedAssessmentGroup] =
    useState<SaveAssessmentGroupMutationVariables>({
      ...defaultAssessmentGroupSettings(AssessmentType.Performance),
      sections: [{ questionIds: [], questionWeights: [] }],
      assessmentType: AssessmentType.Performance,
      isQuestionWeightingEnabled: false,
    });
  const [allQuestions, setAllQuestions] = useState<
    (
      | Pick<
          RangeAssessmentQuestionNode,
          | "__typename"
          | "id"
          | "title"
          | "description"
          | "questionType"
          | "assessmentType"
          | "responses"
          | "responseVisibility"
          | "startValue"
          | "endValue"
          | "labelDescriptions"
          | "labels"
        >
      | Pick<
          MultiChoiceAssessmentQuestionNode,
          | "__typename"
          | "id"
          | "title"
          | "description"
          | "questionType"
          | "assessmentType"
          | "responses"
          | "responseVisibility"
          | "options"
          | "optionDescriptions"
        >
      | Pick<
          TextAssessmentQuestionNode,
          | "__typename"
          | "id"
          | "title"
          | "description"
          | "questionType"
          | "assessmentType"
          | "responses"
          | "responseVisibility"
        >
    )[]
  >([]);

  const [createOrUpdateAssessmentGroup, { loading: isSavingAssessmentGroup }] =
    useMutation<
      SaveAssessmentGroupMutation,
      SaveAssessmentGroupMutationVariables
    >(createOrUpdateAssessmentGroupMutation);

  const { data: assessmentGroupData, loading: isLoadingAssessmentGroup } =
    useQuery<GetAssessmentGroupQuery, GetAssessmentGroupQueryVariables>(
      getAssessmentGroupQuery,
      {
        variables: { assessmentGroupId },
        skip: isNew,
        onCompleted: (response) => {
          if (!response.assessmentGroup) {
            link.redirect("/assessments/assessment-templates");
          } else {
            const sections = assertEdgesNonNull(
              response.assessmentGroup.sections
            );
            setProposedAssessmentGroup({
              ...response.assessmentGroup,
              isQuestionWeightingEnabled:
                response.assessmentGroup.isQuestionWeightingEnabled,
              sections: sections.map((section) => ({
                title: section.title,
                description: section.description,
                questionIds: section.questions.edges.map((edge) =>
                  assertNonNull(edge?.node?.question.id)
                ),
                questionWeights: section.questions.edges.map((edge) =>
                  assertNonNull(edge?.node?.weight)
                ),
              })),
            });
          }
        },
        onError: onNotificationErrorHandler(),
      }
    );

  const { loading: isLoadingAvailableQuestions } = useQuery<
    GetAssessmentQuestionsQuery,
    GetAssessmentQuestionsQueryVariables
  >(getQuestionsQuery, {
    variables: {
      organizationId: organization.id,
    },
    onCompleted: (response) => {
      const questions = response.assessmentQuestions
        ? assertEdgesNonNull(response.assessmentQuestions)
        : [];
      setAllQuestions(questions);
    },
    onError: onNotificationErrorHandler(),
  });

  const availableQuestions = useMemo(
    () =>
      allQuestions.filter(
        (question) =>
          question.assessmentType === proposedAssessmentGroup.assessmentType
      ),
    [allQuestions, proposedAssessmentGroup.assessmentType]
  );

  const handleSaveAssessmentGroup = useCallback(() => {
    createOrUpdateAssessmentGroup({
      variables: {
        ...proposedAssessmentGroup,
        assessmentGroupId: isNew || isDuplicate ? undefined : assessmentGroupId,
        organizationId: organization.id,
      },
      onError,
      refetchQueries: [getAssessmentGroupsQuery],
      onCompleted: () => {
        successNotificationVar({ title: "Assessment template saved" });
        link.redirect(`/assessments/assessment-templates`);
      },
    });
  }, [
    createOrUpdateAssessmentGroup,
    link,
    onError,
    organization,
    proposedAssessmentGroup,
    assessmentGroupId,
    isDuplicate,
    isNew,
  ]);

  const handleAddSection = useCallback(() => {
    setProposedAssessmentGroup({
      ...proposedAssessmentGroup,
      sections: (
        assertNonNull(
          proposedAssessmentGroup.sections
        ) as AssessmentGroupSectionInput[]
      ).concat([
        {
          questionIds: [],
          questionWeights: [],
        },
      ]),
    });
  }, [proposedAssessmentGroup]);

  const handleRemoveSection = useCallback(
    (sectionIndex: number) => {
      const sections = assertNonNull(
        proposedAssessmentGroup.sections
      ) as AssessmentGroupSectionInput[];
      setProposedAssessmentGroup({
        ...proposedAssessmentGroup,
        sections: [
          ...sections.slice(0, sectionIndex),
          ...sections.slice(sectionIndex + 1),
        ],
      });
    },
    [proposedAssessmentGroup]
  );

  const handleUpdateSection = useCallback(
    (newSection: AssessmentGroupSectionInput, sectionIndex: number) => {
      const sections = assertNonNull(
        proposedAssessmentGroup.sections
      ) as AssessmentGroupSectionInput[];
      setProposedAssessmentGroup({
        ...proposedAssessmentGroup,
        sections: [
          ...sections.slice(0, sectionIndex),
          newSection,
          ...sections.slice(sectionIndex + 1),
        ],
      });
    },
    [proposedAssessmentGroup]
  );

  const handleAddQuestion = useCallback(
    (sectionIndex: number) => {
      const sections = assertNonNull(
        proposedAssessmentGroup.sections
      ) as AssessmentGroupSectionInput[];
      const newSection = {
        ...sections[sectionIndex],
        questionIds: [
          ...((sections[sectionIndex].questionIds as Maybe<number>[]) ?? []),
          null,
        ],
        questionWeights: [
          ...((sections[sectionIndex].questionWeights as Maybe<number>[]) ??
            []),
          ASSESSMENT_QUESTION_DEFAULT_WEIGHT,
        ],
      };
      setProposedAssessmentGroup({
        ...proposedAssessmentGroup,
        sections: [
          ...sections.slice(0, sectionIndex),
          newSection,
          ...sections.slice(sectionIndex + 1),
        ],
      });
    },
    [proposedAssessmentGroup]
  );

  const sections = useMemo(() => {
    return proposedAssessmentGroup.sections as AssessmentGroupSectionInput[];
  }, [proposedAssessmentGroup]);

  const allQuestionIds: number[] = useMemo(
    () => flatMap(sections, "questionIds"),
    [sections]
  );
  const allQuestionWeights: number[] = useMemo(
    () => flatMap(sections, "questionWeights"),
    [sections]
  );

  const handleDragEnd: (sectionIndex: number) => OnDragEndResponder =
    useCallback(
      (sectionIndex: number) =>
        ({ destination, draggableId, source, reason }) => {
          if (reason === "DROP" && destination) {
            const sections = assertNonNull(
              proposedAssessmentGroup.sections
            ) as AssessmentGroupSectionInput[];
            const section = sections[sectionIndex];
            const newQuestionIds = [...section.questionIds];
            const newQuestionWeights = [
              ...(section.questionWeights as number[]),
            ];
            const idElm = newQuestionIds.splice(source.index, 1)[0];
            const weightElm = newQuestionWeights.splice(source.index, 1)[0];
            newQuestionIds.splice(destination.index, 0, idElm);
            newQuestionWeights.splice(destination.index, 0, weightElm);
            const newSection = {
              ...sections[sectionIndex],
              questionIds: newQuestionIds,
              questionWeights: newQuestionWeights,
            };
            setProposedAssessmentGroup({
              ...proposedAssessmentGroup,
              sections: [
                ...sections.slice(0, sectionIndex),
                newSection,
                ...sections.slice(sectionIndex + 1),
              ],
            });
          }
        },
      [proposedAssessmentGroup]
    );

  const deliveryOptions = useMemo(
    () =>
      assessmentGroupDeliveryOptions(proposedAssessmentGroup.assessmentType),
    [proposedAssessmentGroup]
  );
  const providerOptions = useMemo(
    () =>
      assessmentGroupProviderOptions(proposedAssessmentGroup.assessmentType),
    [proposedAssessmentGroup]
  );
  const anonymityOptions = useMemo(
    () =>
      assessmentGroupAnonymityOptions(proposedAssessmentGroup.assessmentType),
    [proposedAssessmentGroup]
  );

  if (isLoadingAssessmentGroup || isLoadingAvailableQuestions) {
    return (
      <div
        className={classNames(bgClassName, "flex-1 flex justify-center p-10")}
      >
        <Loading>Loading assessment template..</Loading>
      </div>
    );
  }

  const canUpdate =
    isNew ||
    isDuplicate ||
    !!assessmentGroupData?.assessmentGroup?.canUpdate?.permission;
  const canUpdateMessage =
    assessmentGroupData?.assessmentGroup?.canUpdate?.reason ?? "Can't edit";
  const canSaveTooltip =
    !proposedAssessmentGroup.title ||
    proposedAssessmentGroup.title.trim().length === 0
      ? "Please enter a title"
      : allQuestionIds.length === 0
      ? "Assessment templates require at least 1 question"
      : allQuestionIds.filter((q) => q === null).length > 0
      ? "Please select a question"
      : allQuestionWeights.filter(
          (w) => w !== null && w !== undefined && !isNaN(w)
        ).length !== allQuestionWeights.length
      ? "Please enter weights for all questions"
      : !canUpdate
      ? canUpdateMessage
      : null;

  return (
    <form
      className={classNames(bgClassName, "p-6 flex-1 min-h-92")}
      aria-label="Assessment template form"
    >
      <div className="flex items-center justify-between mb-4">
        <div className="text-xl font-medium">
          {isNew ? "New" : isDuplicate ? "Duplicate" : "Edit"} assessment
          template
        </div>
        <div className="flex justify-end items-center gap-2 sm:gap-4">
          <Button
            to="/assessments/assessment-templates"
            theme={buttonTheme.text}
            disabled={isSavingAssessmentGroup}
          >
            Discard changes
          </Button>
          <Button
            type="button"
            onClick={handleSaveAssessmentGroup}
            tooltip={canSaveTooltip}
            disabled={!!canSaveTooltip || isSavingAssessmentGroup}
            theme={buttonTheme.primary}
          >
            {`${
              isSavingAssessmentGroup ? "Saving" : "Save"
            } assessment template`}
          </Button>
        </div>
      </div>
      <div className="sm:flex">
        <div className="sm:w-96 flex flex-col text-sm gap-2">
          <div className="flex flex-col gap-4 sm:gap-6">
            <div className="flex flex-col gap-2">
              <div className="text-gray-500 text-xs uppercase font-semibold">
                Name
              </div>
              <Input
                disabled={!canUpdate}
                aria-label="Assessment template title input"
                value={proposedAssessmentGroup.title ?? ""}
                onChange={(e) =>
                  setProposedAssessmentGroup({
                    ...proposedAssessmentGroup,
                    title: e.target.value,
                  })
                }
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="text-gray-500 text-xs uppercase font-semibold">
                Assessment type
              </div>
              <Select<AssessmentType>
                width="full"
                disabled={!canUpdate}
                value={assertNonNull(proposedAssessmentGroup.assessmentType)}
                options={assessmentTypeOptions}
                onChange={(opt) =>
                  setProposedAssessmentGroup({
                    ...proposedAssessmentGroup,
                    ...defaultAssessmentGroupSettings(opt.value),
                    assessmentType: opt.value,
                    sections: [
                      {
                        questionIds: [],
                        questionWeights: [],
                      },
                    ],
                  })
                }
                aria-label="Assessment type select"
              />
            </div>
            {deliveryOptions.length > 0 && (
              <div className="flex flex-col gap-2">
                <div className="text-gray-500 text-xs uppercase font-semibold">
                  Delivery
                </div>
                <Select<AssessmentGroupDelivery>
                  width="full"
                  disabled={!canUpdate}
                  value={assertNonNull(proposedAssessmentGroup.delivery)}
                  options={deliveryOptions}
                  onChange={(opt) =>
                    setProposedAssessmentGroup({
                      ...proposedAssessmentGroup,
                      delivery: opt.value,
                    })
                  }
                  aria-label="Assessment delivery select"
                />
              </div>
            )}
            {providerOptions.length > 0 && (
              <div className="flex flex-col gap-2">
                <div className="text-gray-500 text-xs uppercase font-semibold">
                  Providers
                </div>
                <Select<AssessmentGroupProviders>
                  width="full"
                  disabled={!canUpdate}
                  value={assertNonNull(proposedAssessmentGroup.providers)}
                  options={providerOptions}
                  onChange={(opt) =>
                    setProposedAssessmentGroup({
                      ...proposedAssessmentGroup,
                      providers: opt.value,
                    })
                  }
                  aria-label="Assessment providers select"
                />
              </div>
            )}
            {anonymityOptions.length > 0 && (
              <div className="flex flex-col gap-2">
                <div className="text-gray-500 text-xs uppercase font-semibold">
                  Anonymity
                </div>
                <Select<AssessmentGroupAnonymity>
                  width="full"
                  disabled={!canUpdate}
                  value={assertNonNull(proposedAssessmentGroup.anonymity)}
                  options={anonymityOptions}
                  onChange={(opt) =>
                    setProposedAssessmentGroup({
                      ...proposedAssessmentGroup,
                      anonymity: opt.value,
                    })
                  }
                  aria-label="Assessment anonymity select"
                />
              </div>
            )}
            <div className="flex flex-col gap-2">
              <div className="text-gray-500 text-xs uppercase font-semibold">
                Enable weighted scoring
              </div>
              <Select
                width="full"
                disabled={!canUpdate}
                value={proposedAssessmentGroup.isQuestionWeightingEnabled}
                options={[
                  {
                    label: "No",
                    value: false,
                  },
                  {
                    label: "Yes",
                    value: true,
                  },
                ]}
                onChange={(opt) =>
                  setProposedAssessmentGroup({
                    ...proposedAssessmentGroup,
                    isQuestionWeightingEnabled: opt.value,
                  })
                }
                aria-label="Assessment weighting enabled select"
              />
            </div>
          </div>
        </div>
        <div className="flex-1 pl-8">
          <div className="text-gray-500 text-xs uppercase font-semibold mb-2">
            Questions
          </div>
          {sections.map((section, sectionIndex) => (
            <div
              className="p-8 border border-gray-300 bg-white rounded-lg mb-4"
              key={sectionIndex}
            >
              <DragDropContext onDragEnd={handleDragEnd(sectionIndex)}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <ul
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      aria-label="Assessment template question list"
                    >
                      {section.title !== null && section.title !== undefined ? (
                        <>
                          <Input
                            disabled={!canUpdate}
                            aria-label="Section input"
                            className="w-full"
                            placeholder="Heading"
                            value={section.title ?? ""}
                            onChange={(e) => {
                              const newSection = {
                                ...section,
                                title: e.target.value,
                              };
                              const sections = assertNonNull(
                                proposedAssessmentGroup.sections
                              ) as AssessmentGroupSectionInput[];
                              setProposedAssessmentGroup({
                                ...proposedAssessmentGroup,
                                sections: [
                                  ...sections.slice(0, sectionIndex),
                                  newSection,
                                  ...sections.slice(sectionIndex + 1),
                                ],
                              });
                            }}
                          />
                          <TextareaWysiwyg
                            editable={canUpdate}
                            className="mt-1 bg-white"
                            placeholder="Section decription"
                            value={section.description}
                            deps={[section.title]}
                            onChangeValue={(description: string) => {
                              const newSection = {
                                ...section,
                                description,
                              };
                              const sections = assertNonNull(
                                proposedAssessmentGroup.sections
                              ) as AssessmentGroupSectionInput[];
                              setProposedAssessmentGroup({
                                ...proposedAssessmentGroup,
                                sections: [
                                  ...sections.slice(0, sectionIndex),
                                  newSection,
                                  ...sections.slice(sectionIndex + 1),
                                ],
                              });
                            }}
                          />
                        </>
                      ) : canUpdate ? (
                        <Button
                          small
                          text="Add heading"
                          theme="text"
                          onClick={() => {
                            const newSection = {
                              ...section,
                              title: "",
                            };
                            const sections = assertNonNull(
                              proposedAssessmentGroup.sections
                            ) as AssessmentGroupSectionInput[];
                            setProposedAssessmentGroup({
                              ...proposedAssessmentGroup,
                              sections: [
                                ...sections.slice(0, sectionIndex),
                                newSection,
                                ...sections.slice(sectionIndex + 1),
                              ],
                            });
                          }}
                        />
                      ) : null}
                      {section.questionIds.map((questionId, questionIndex) => (
                        <AssessmentGroupQuestionItem
                          key={`${questionId}_${questionIndex}`}
                          assessmentType={assertNonNull(
                            proposedAssessmentGroup.assessmentType
                          )}
                          canUpdate={canUpdate}
                          availableQuestions={availableQuestions}
                          selectedQuestionIds={allQuestionIds}
                          questionId={questionId}
                          weight={
                            assertNonNull(section.questionWeights)[
                              questionIndex
                            ]
                          }
                          isQuestionWeightingEnabled={assertNonNull(
                            proposedAssessmentGroup.isQuestionWeightingEnabled
                          )}
                          index={questionIndex}
                          onChangeQuestionId={(questionId: number) => {
                            const question = allQuestions.find(
                              (q) => q.id === questionId
                            );
                            const questionWeights = assertNonNull(
                              section.questionWeights
                            ) as number[];
                            const newSection = {
                              ...section,
                              questionIds: [
                                ...section.questionIds.slice(0, questionIndex),
                                questionId,
                                ...section.questionIds.slice(questionIndex + 1),
                              ],
                              questionWeights: [
                                ...questionWeights.slice(0, questionIndex),
                                question?.questionType ===
                                AssessmentQuestionType.Range
                                  ? questionWeights[questionIndex]
                                  : 0,
                                ...questionWeights.slice(questionIndex + 1),
                              ],
                            };
                            handleUpdateSection(newSection, sectionIndex);
                          }}
                          onChangeWeight={(weight: number) => {
                            const questionWeights = assertNonNull(
                              section.questionWeights
                            ) as number[];
                            const newSection = {
                              ...section,
                              questionWeights: [
                                ...questionWeights.slice(0, questionIndex),
                                weight,
                                ...questionWeights.slice(questionIndex + 1),
                              ],
                            };
                            handleUpdateSection(newSection, sectionIndex);
                          }}
                          onDelete={() => {
                            const questionWeights = assertNonNull(
                              section.questionWeights
                            ) as number[];
                            const newSection = {
                              ...section,
                              questionIds: [
                                ...section.questionIds.slice(0, questionIndex),
                                ...section.questionIds.slice(questionIndex + 1),
                              ],
                              questionWeights: [
                                ...questionWeights.slice(0, questionIndex),
                                ...questionWeights.slice(questionIndex + 1),
                              ],
                            };
                            handleUpdateSection(newSection, sectionIndex);
                          }}
                          onNewQuestionCreated={(question) => {
                            setAllQuestions([...allQuestions, question]);
                            const questionWeights = assertNonNull(
                              section.questionWeights
                            ) as number[];
                            const newSection = {
                              ...section,
                              questionIds: [
                                ...section.questionIds.slice(0, questionIndex),
                                question.id,
                                ...section.questionIds.slice(questionIndex + 1),
                              ],
                              questionWeights: [
                                ...questionWeights.slice(0, questionIndex),
                                question.questionType ===
                                AssessmentQuestionType.Range
                                  ? ASSESSMENT_QUESTION_DEFAULT_WEIGHT
                                  : 0,
                                ...questionWeights.slice(questionIndex + 1),
                              ],
                            };
                            handleUpdateSection(newSection, sectionIndex);
                          }}
                        />
                      ))}
                      {availableQuestions.length > allQuestionIds.length &&
                        canUpdate && (
                          <div className="py-4 flex items-center gap-4">
                            <Button
                              disabled={!canUpdate}
                              theme="text"
                              small
                              text="Add question"
                              onClick={() => handleAddQuestion(sectionIndex)}
                            />
                            <Button
                              disabled={!canUpdate}
                              theme="text"
                              small
                              text="Add section below"
                              onClick={handleAddSection}
                            />
                            {(
                              assertNonNull(
                                proposedAssessmentGroup.sections
                              ) as AssessmentGroupSectionInput[]
                            ).length > 1 && (
                              <Button
                                disabled={!canUpdate}
                                theme={buttonTheme.redDanger}
                                small
                                text="Remove section"
                                onClick={() =>
                                  handleRemoveSection(sectionIndex)
                                }
                              />
                            )}
                          </div>
                        )}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          ))}
          {allQuestionWeights.length > 0 &&
            proposedAssessmentGroup.isQuestionWeightingEnabled && (
              <div className="text-sm text-right text-gray-500 pr-2">
                Total weight:{" "}
                {compact(allQuestionWeights).reduce(
                  (sum, weight) => sum + weight,
                  0
                )}
              </div>
            )}
        </div>
      </div>
    </form>
  );
};

export default AssessmentGroupEdit;
