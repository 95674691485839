import { Menu } from "@szhsin/react-menu";
import { compact } from "lodash";
import { ArtifactType, GoalScope } from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";

import {
  ExplorerFilterType,
  explorerAllowedArtifactTypes,
  explorerGithubType,
  explorerHubspotType,
  explorerJiraType,
  explorerMeetingType,
  explorerUserType,
} from "../helpers";
import ExplorerMenuButton from "./explorer-menu-button";
import ExplorerMenuOptions from "./explorer-menu-options";

const integrationSubmenu = compact([
  {
    value: explorerGithubType,
    filters: { type: explorerGithubType },
    label: `Github`,
  },
  {
    value: explorerJiraType,
    filters: { type: explorerJiraType },
    label: `Jira`,
  },
  {
    value: explorerHubspotType,
    filters: { type: explorerHubspotType },
    label: `Hubspot`,
  },
]);

const ExplorerTypeDropdown = ({
  filters,
  onChangeFilters,
}: {
  filters: ExplorerFilterType;
  onChangeFilters: (filters: ExplorerFilterType) => void;
}) => {
  const label = useLabel();

  const artifactTypeOptions = explorerAllowedArtifactTypes().map((value) => {
    return value === ArtifactType.Goal
      ? {
          value,
          label: label(value, { capitalize: true }),
          filters: {},
          submenu: [
            {
              value: null,
              filters: { type: ArtifactType.Goal, goalScope: null },
              label: `All ${label("goal")} types`,
            },
            {
              value: GoalScope.Career,
              filters: {
                type: ArtifactType.Goal,
                goalScope: GoalScope.Career,
              },
              label: `Career ${label("goal", { capitalize: true })}`,
            },
            {
              value: GoalScope.Personal,
              filters: {
                type: ArtifactType.Goal,
                goalScope: GoalScope.Personal,
              },
              label: `Individual ${label("goal", { capitalize: true })}`,
            },
            {
              value: GoalScope.Team,
              filters: {
                type: ArtifactType.Goal,
                goalScope: GoalScope.Team,
              },
              label: `${label("team", { capitalize: true })} ${label("goal")}`,
            },
            {
              value: GoalScope.Organization,
              filters: {
                type: ArtifactType.Goal,
                goalScope: GoalScope.Organization,
              },
              label: `${label("organization", {
                capitalize: true,
              })} ${label("goal")}`,
            },
          ],
        }
      : {
          value: value,
          filters: { type: value, goalScope: null },
          selectedLabel: label(value, { capitalize: true }),
          label: label(value, { capitalize: true }),
        };
  });
  const typeOptions = compact([
    {
      label: "All result types",
      selectedLabel: "All result types",
      value: null,
      filters: { type: null, goalScope: null },
    },
    ...artifactTypeOptions,
    {
      label: "Users",
      selectedLabel: "Users",
      value: explorerUserType,
      filters: { type: explorerUserType, goalScope: null },
    },
    {
      label: "Meetings",
      selectedLabel: "Meetings",
      value: explorerMeetingType,
      filters: { type: explorerMeetingType },
    },
    integrationSubmenu.length && {
      value: "integration",
      label: "Integrations",
      submenu: integrationSubmenu,
      filters: {},
    },
  ]);

  const handleSelectMenuItem = (updatedFilters: ExplorerFilterType) => {
    onChangeFilters(updatedFilters);
  };

  // RENDER
  return (
    <div>
      <Menu
        className="text-sm w-full z-dropdown"
        portal
        transition={false}
        menuButton={
          <ExplorerMenuButton
            options={typeOptions}
            filters={filters}
            prefix="Type"
          />
        }
      >
        <ExplorerMenuOptions
          options={typeOptions}
          onSelectMenuItem={handleSelectMenuItem}
        />
      </Menu>
    </div>
  );
};

export default ExplorerTypeDropdown;
