import { useMutation } from "@apollo/client";

import {
  ExplorerFilterType,
  explorerAllowedArtifactTypes,
  getQueryVariablesForExplorerFilters,
} from "@apps/explorer/helpers";
import { successNotificationVar } from "@cache/cache";
import Tooltip from "@components/tooltip/tooltip";
import { useNotificationError } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";

import exportExplorerToCSV from "../graphql/export-to-csv-mutation";
import {
  explorerSelectClassName,
  explorerSelectDisabledClassName,
} from "./filters";

const ExplorerExport: React.FC<{ filters: ExplorerFilterType }> = ({
  filters,
}) => {
  const { onError } = useNotificationError();
  const [triggerExport, { loading }] = useMutation(exportExplorerToCSV);

  const handleClickExport = () => {
    const queryFilters = getQueryVariablesForExplorerFilters(filters, null);
    triggerExport({ variables: queryFilters, onError }).then(({ data }) => {
      successNotificationVar({
        title: `CSV export will be emailed to ${data.searchCsvExport.user.email}`,
      });
    });
  };

  const exportDisabled = !(
    filters.type && explorerAllowedArtifactTypes().includes(filters.type)
  );
  const buttonDisabled = exportDisabled || loading;

  return (
    <div>
      <Tooltip
        text={
          exportDisabled
            ? "You can only export results containing a single type of Topicflow data"
            : "Export will be sent via email"
        }
      >
        <button
          className={classNames(
            explorerSelectClassName,
            "pr-2",
            buttonDisabled && explorerSelectDisabledClassName
          )}
          disabled={exportDisabled || loading}
          onClick={handleClickExport}
        >
          Export to csv
        </button>
      </Tooltip>
    </div>
  );
};

export default ExplorerExport;
