import { Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { useEffect } from "react";
import { Droppable } from "react-beautiful-dnd";
import { MeetingViewMeetingNodeFragmentFragment } from "types/graphql-schema";

import MeetingTimer from "@apps/meeting/components/meeting-timer";
import Topic from "@apps/meeting/components/topic";
import TopicCreateForm from "@apps/meeting/components/topic-create-form";
import { assertEdgesNonNull } from "@helpers/helpers";

export const meetingDialogInputClassName =
  "hover:bg-gray-100 rounded text-sm px-2 py-1.5 focus:outline-0 focus:ring-0";

const MeetingPresentationDialog = ({
  onClose,
  meeting,
}: {
  onClose: () => void;
  meeting: MeetingViewMeetingNodeFragmentFragment;
}) => {
  useEffect(() => {
    document.querySelector("html")?.classList.add("text-xl");
    return function cleanup() {
      document.querySelector("html")?.classList.remove("text-xl");
    };
  });

  const topics = assertEdgesNonNull(meeting.topics);
  return (
    <Dialog
      as="div"
      aria-label="Meeting presentation dialog"
      className="fixed z-presentation inset-0 bg-white"
      open
      onClose={() => {}}
    >
      <div className="text-gray-800 flex flex-col h-full">
        <div className="bg-gray-800 text-gray-200 flex items-center justify-between py-2 px-4">
          <div className="flex items-center gap-8">
            <div className="text-gray-100 text-xl">{meeting.title}</div>
            {meeting.startDatetime && meeting.endDatetime && (
              <MeetingTimer
                id={meeting.id}
                startDatetime={meeting.startDatetime}
                endDatetime={meeting.endDatetime}
              />
            )}
          </div>
          <button
            onClick={onClose}
            className="flex items-center gap-2 rounded-full border border-gray-600 text-sm px-3 py-1.5 hover:outline-none hover:bg-gray-700 hover:ring-0 focus:ring-0 focus:outline-none"
          >
            <XIcon className="h-5 w-5 text-gray-400" />
            Stop presenting
          </button>
        </div>
        <div className="py-12 flex-1 overflow-y-auto min-h-0">
          <div className="w-full max-w-4xl mx-auto">
            <Droppable droppableId={`meeting-${meeting.id}`}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <ul
                    className="mt-3 fs-mask border-t border-b divide-y empty:hidden"
                    aria-label="Topic list"
                  >
                    {topics.map((topic, index) => (
                      <Topic
                        key={topic.id}
                        index={index}
                        topic={topic}
                        meeting={meeting}
                        opensInSidebar={false}
                        currentPageMeeting={meeting}
                        searchQuery=""
                        relevantSections={null}
                      />
                    ))}
                    {provided.placeholder}
                  </ul>
                  <div>
                    <TopicCreateForm
                      key={`create-topic-form-${meeting.id}`}
                      meetingGroup={meeting.meetingGroup}
                      meeting={meeting}
                    />
                  </div>
                </div>
              )}
            </Droppable>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default MeetingPresentationDialog;
