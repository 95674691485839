import * as Sentry from "@sentry/react";
import { generateHTML } from "@tiptap/core";
import Bold from "@tiptap/extension-bold";
import Document from "@tiptap/extension-document";
import HardBreak from "@tiptap/extension-hard-break";
import Link from "@tiptap/extension-link";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import { range } from "lodash";
import { MdOutlineChat } from "react-icons/md";
import {
  AssessmentQuestionType,
  GetComplianceProgramForReportQuery,
  RangeAssessmentQuestionNode,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import {
  matchesReportsToFilter,
  matchesTeamsFilter,
  matchesUserFilter,
} from "@apps/reporting/helpers";
import Avatar from "@components/avatar/avatar";
import Table, {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
} from "@components/table/table";
import Tooltip from "@components/tooltip/tooltip";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import {
  assertEdgesNonNull,
  assertNonNull,
  parseStringToJSON,
} from "@helpers/helpers";

import { PerformanceAssessmentAnswerFilter } from "../assessment-report";
import { TeamPickerTeam } from "../team-picker";

const PerformanceAssessmentTable = ({
  questions,
  complianceProgram,
  userFilterList,
  reportsToFilterList,
  answerFilterList,
  teamFilterList,
}: {
  complianceProgram: NonNullable<
    GetComplianceProgramForReportQuery["complianceProgram"]
  >;
  questions: Pick<
    RangeAssessmentQuestionNode,
    "id" | "title" | "startValue" | "endValue" | "labels" | "questionType"
  >[];
  userFilterList: BasicUser[];
  reportsToFilterList: BasicUser[];
  teamFilterList: TeamPickerTeam[];
  answerFilterList: PerformanceAssessmentAnswerFilter[];
}) => {
  const assessmentGroup = assertNonNull(
    complianceProgram.performanceAssessmentGroup
  );
  if (assessmentGroup.isOnlySelfAssessment) {
    return (
      <div className="mx-auto my-12 text-center text-gray-400">
        This performance assessment only contains self assessment questions.
        There is no data to display.
      </div>
    );
  }

  return (
    <TableContainer scroll>
      <Table>
        <TableHeadRow>
          <TableHeadCell>Name</TableHeadCell>
          <TableHeadCell>Manager</TableHeadCell>
          {questions.map((question) => {
            if (question.questionType === AssessmentQuestionType.Text) {
              return null;
            }
            return (
              <TableHeadCell key={question.id}>{question.title}</TableHeadCell>
            );
          })}
        </TableHeadRow>
        <TableBody>
          {complianceProgram.assessments.edges
            .filter(
              (assessment) => assessment?.node?.group.id === assessmentGroup.id
            )
            .map((assessment) => {
              const response = assertNonNull(assessment?.node);
              const responder = assertNonNull(response.responder);
              const target = assertNonNull(response.target);
              const answers = assertEdgesNonNull(response.answers);
              if (!matchesUserFilter(userFilterList, target)) {
                return null;
              }
              if (!matchesReportsToFilter(reportsToFilterList, target)) {
                return null;
              }
              if (!matchesTeamsFilter(teamFilterList, target)) {
                return null;
              }
              if (responder.id === target.id) {
                // don't show self assessments for now
                return null;
              }
              if (
                answerFilterList.length > 0 &&
                !answerFilterList.every(
                  (answerFilter) =>
                    !!answers.find(
                      (a) =>
                        a.question.id === answerFilter.questionId &&
                        a.__typename === "RangeAssessmentAnswerNode" &&
                        a.integerAnswer === answerFilter.integerAnswer
                    )
                )
              ) {
                return null;
              }
              return (
                <TableBodyRow key={response.id}>
                  <TableBodyCell>
                    <div className="flex items-center">
                      <Avatar className="mr-2" size="6" user={target} />{" "}
                      {target.name}
                    </div>
                  </TableBodyCell>
                  <TableBodyCell>{responder.name}</TableBodyCell>
                  {questions.map((question) => {
                    if (question.questionType === AssessmentQuestionType.Text) {
                      return null;
                    }

                    const qLabels = range(
                      question.startValue,
                      question.endValue + 1
                    ).reduce(
                      (labelObj, value, index) => ({
                        ...labelObj,
                        [value]: question.labels[index] || value,
                      }),
                      {} as { [key: number]: string | number }
                    );
                    const answer = answers.find(
                      (a) => a.question.id === question.id
                    );
                    if (
                      !answer ||
                      answer.__typename !== "RangeAssessmentAnswerNode"
                    ) {
                      return (
                        <TableBodyCell key={question.id}>N/A</TableBodyCell>
                      );
                    }

                    let commentHtml = null;
                    if (!isEmptyValue(answer.comment)) {
                      try {
                        commentHtml = generateHTML(
                          parseStringToJSON(answer.comment),
                          [Document, Paragraph, Text, Link, Bold, HardBreak]
                        );
                      } catch (e) {
                        Sentry.captureException(
                          "Performance assessment comment rendering problem",
                          { extra: { error: e } }
                        );
                      }
                    }
                    const integerAnswer = assertNonNull(answer.integerAnswer);
                    return (
                      <TableBodyCell key={question.id}>
                        {commentHtml === null ? (
                          qLabels[integerAnswer]
                        ) : (
                          <div className="flex items-center">
                            {qLabels[integerAnswer]}
                            <Tooltip text={commentHtml}>
                              <MdOutlineChat className="h-4 w-4 ml-2 mt-1" />
                            </Tooltip>
                          </div>
                        )}
                      </TableBodyCell>
                    );
                  })}
                </TableBodyRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PerformanceAssessmentTable;
