import { useQuery } from "@apollo/client";
import moment from "moment";
import { Fragment, MouseEvent, useCallback, useState } from "react";
import {
  AssessmentType,
  GetMeetingRecentlyCompletedAssessmentDeliveriesQueryQuery,
  GetMeetingRecentlyCompletedAssessmentDeliveriesQueryQueryVariables,
  MeetingViewMeetingNodeFragmentFragment,
  RecentlyCompletedAssessmentDeliveryFragment,
} from "types/graphql-schema";

import AssessmentSidebar from "@apps/assessments/components/assessment-sidebar/assessment-sidebar";
import getMeetingRecentlyCompletedAssessmentDeliveriesQuery from "@apps/meeting/graphql/get-meeting-recently-completed-assessment-deliveries-query";
import useUiPreferenceCache, {
  UiPreferenceCache,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import Avatar from "@components/avatar/avatar";
import CollapsibleContainer from "@components/collapsible-container/collapsible-container";
import CollapsibleContainerParent from "@components/collapsible-container/collapsible-container-parent";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { batchClient } from "@graphql/client";
import { assertEdgesNonNull, getAssessmentTypeString } from "@helpers/helpers";

const RecentlyCompletedAssessmentDeliveryItem = ({
  assessmentDelivery,
}: {
  assessmentDelivery: RecentlyCompletedAssessmentDeliveryFragment;
}) => {
  const [showSidebar, setShowSidebar] = useState(false);

  const handleClickDeliveryLink = (e: MouseEvent<HTMLButtonElement>) => {
    setShowSidebar(true);
  };

  return (
    <div className="px-4 py-2 text-sm">
      {showSidebar && (
        <AssessmentSidebar
          assessmentDeliveryId={assessmentDelivery.id}
          onClose={() => setShowSidebar(false)}
        />
      )}
      <div className="flex items-center gap-1.5">
        <Avatar user={assessmentDelivery.target} size={4} />
        <button
          type="button"
          className="text-blue-link hover:underline"
          onClick={handleClickDeliveryLink}
        >
          {assessmentDelivery.target?.name}:{" "}
          {getAssessmentTypeString(
            assessmentDelivery.group.assessmentType
          ).toLowerCase()}{" "}
          {assessmentDelivery.group.hasSelfAssessment ? "self " : ""}
          assessment
        </button>
      </div>
      <div className="text-xs flex justify-between mt-1 text-gray-500 ml-5 pl-0.5">
        <span>{assessmentDelivery.complianceProgram?.title}</span>
        {assessmentDelivery.deliveryDatetime ? (
          <span className="text-gray-400">
            {moment(assessmentDelivery.deliveryDatetime).format("LLL")}
          </span>
        ) : assessmentDelivery.lastSubmissionDatetime ? (
          <span className="text-gray-400">
            {moment(assessmentDelivery.lastSubmissionDatetime).format("LLL")}
          </span>
        ) : null}
      </div>
    </div>
  );
};

const RecentlyCompletedAssessmentDeliveryCollapsibleContainer = ({
  meeting,
  assessmentType,
  isLast,
  collapseKey,
  title,
}: {
  meeting: MeetingViewMeetingNodeFragmentFragment;
  assessmentType: AssessmentType;
  isLast: boolean;
  collapseKey: keyof UiPreferenceCache;
  title: string;
}) => {
  const { data, loading, fetchMore } = useQuery<
    GetMeetingRecentlyCompletedAssessmentDeliveriesQueryQuery,
    GetMeetingRecentlyCompletedAssessmentDeliveriesQueryQueryVariables
  >(getMeetingRecentlyCompletedAssessmentDeliveriesQuery, {
    variables: { meetingId: meeting.id, assessmentType, limit: 2 },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    client: batchClient,
    onError: onNotificationErrorHandler(),
  });
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const assessmentDeliveries = data?.meeting
    ?.recentlyCompletedAssessmentDeliveries
    ? assertEdgesNonNull(data.meeting.recentlyCompletedAssessmentDeliveries)
    : [];

  const handleToggleCollapsible = useCallback(
    (isOpen: boolean) => {
      saveUiPreference({ [`${collapseKey}`]: isOpen });
    },
    [saveUiPreference, collapseKey]
  );

  const handleClickLoadMore = () => {
    if (
      data?.meeting?.recentlyCompletedAssessmentDeliveries.pageInfo.hasNextPage
    ) {
      fetchMore({
        variables: {
          limit: 5,
          after:
            data.meeting?.recentlyCompletedAssessmentDeliveries.pageInfo
              .endCursor,
          merge: true,
        },
      });
    }
  };

  return (
    <CollapsibleContainer
      container={Fragment}
      startOpen={uiPreferenceCache[`${collapseKey}`]}
      roundedBottom={isLast}
      title={title}
      onToggle={handleToggleCollapsible}
    >
      {!data && loading ? (
        <div className="px-4 py-2 flex items-center justify-center">
          <Loading mini size={5} />
        </div>
      ) : (
        <div className="divide-y divide-gray-100 overflow-hidden">
          {assessmentDeliveries.map((assessmentDelivery) => (
            <RecentlyCompletedAssessmentDeliveryItem
              assessmentDelivery={assessmentDelivery}
              key={assessmentDelivery.id}
            />
          ))}
          {loading ? (
            <div className="px-4 py-2 text-xs text-gray-500 flex justify-center">
              <Loading mini size="4" />
            </div>
          ) : (
            data?.meeting?.recentlyCompletedAssessmentDeliveries.pageInfo
              .hasNextPage && (
              <div className="px-4 py-2 text-xs text-gray-500 flex justify-center">
                <button onClick={handleClickLoadMore}>Load more</button>
              </div>
            )
          )}
          {assessmentDeliveries.length === 0 && (
            <div className="px-4 py-2 text-sm text-gray-500">
              No assessment deliveries.
            </div>
          )}
        </div>
      )}
    </CollapsibleContainer>
  );
};

const RecentlyCompletedAssessmentDeliveries = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeFragmentFragment;
}) => {
  const assessmentGroups: {
    title: string;
    collapseKey: keyof UiPreferenceCache;
    assessmentType: AssessmentType;
  }[] = [
    {
      title: "Performance",
      assessmentType: AssessmentType.Performance,
      collapseKey: "oneononePerformanceAssessments",
    },
    {
      title: "Manager effectiveness",
      assessmentType: AssessmentType.Manager,
      collapseKey: "oneononeManagerAssessments",
    },
    {
      title: "Peer",
      assessmentType: AssessmentType.Peer,
      collapseKey: "oneononePeerAssessments",
    },
  ];

  return (
    <CollapsibleContainerParent title="Assessments recently delivered">
      {assessmentGroups.map((assessmentGroup, i) => (
        <RecentlyCompletedAssessmentDeliveryCollapsibleContainer
          meeting={meeting}
          collapseKey={assessmentGroup.collapseKey}
          title={assessmentGroup.title}
          assessmentType={assessmentGroup.assessmentType}
          key={assessmentGroup.title}
          isLast={i === assessmentGroups.length - 1}
        />
      ))}
    </CollapsibleContainerParent>
  );
};
export default RecentlyCompletedAssessmentDeliveries;
