import { ReactElement } from "react";

export const CollapsibleEmpty = ({
  children,
}: {
  children: ReactElement | string[];
}) => {
  return <div className="pl-4 py-2 text-slate-500 text-sm">{children}</div>;
};

export default CollapsibleEmpty;
