import { useMutation, useQuery } from "@apollo/client";
import { compact } from "lodash";
import moment from "moment";
import { ChangeEventHandler, useCallback, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { useLocation, useParams } from "react-router-dom";
import {
  AssessmentGroupComplianceRequirement,
  AssessmentType,
  ComplianceProgramAppliesTo,
  ComplianceProgramRecurrence,
  ComplianceProgramState,
  GetAssessmentGroupsQuery,
  GetAssessmentGroupsQueryVariables,
  GetComplianceProgramQuery,
  GetComplianceProgramQueryVariables,
  GetTopicTemplatesQuery,
  GetTopicTemplatesQueryVariables,
  Maybe,
  SaveComplianceProgramMutation,
  SaveComplianceProgramMutationVariables,
  TopicTemplateComplianceRequirement,
} from "types/graphql-schema";
import { PastOnlyDateRangeEnum } from "types/topicflow";

import RecipientForm from "@apps/artifact/components/recipient-form";
import TeamPicker from "@apps/reporting/components/team-picker";
import getTemplatesQuery from "@apps/templates/graphql/get-templates-query";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar, successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import ComboboxGeneric, {
  ComboboxGenericOption,
} from "@components/combobox/generic-combobox";
import Input from "@components/input/input";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import { Select, SelectOption } from "@components/select/select";
import {
  onNotificationErrorHandler,
  useNotificationError,
} from "@components/use-error/use-error";
import {
  assertEdgesNonNull,
  assertNonNull,
  dateRangeToDateArray,
} from "@helpers/helpers";
import { capitalize, removeUnderscores } from "@helpers/string";

import getAssessmentGroupsQuery from "../../assessments/graphql/get-assessment-groups-query";
import createOrUpdateComplianceProgramMutation from "../graphql/create-or-update-compliance-program-mutation";
import getComplianceProgramQuery from "../graphql/get-compliance-program-query";
import getComplianceProgramsQuery from "../graphql/get-compliance-programs-query";

const complianceProgramUrl = "/programs";

const appliesToOptions = [
  { value: ComplianceProgramAppliesTo.Organization, label: "Organization" },
  { value: ComplianceProgramAppliesTo.Departments, label: "Departments" },
  { value: ComplianceProgramAppliesTo.Managers, label: "Managers" },
  { value: ComplianceProgramAppliesTo.Users, label: "Users" },
];

enum ExtraDateRangeEnum {
  none = "none",
  custom = "custom",
}
type DateRangeType = ExtraDateRangeEnum | PastOnlyDateRangeEnum;

const ComplianceProgramEdit = () => {
  const link = useLink();
  const location = useLocation();
  const organization = currentOrganizationVar();
  const { onError } = useNotificationError();
  const { complianceProgramId: complianceProgramIdParam } = useParams<{
    complianceProgramId: string;
  }>();
  const complianceProgramId = parseInt(complianceProgramIdParam);
  const isNew = isNaN(complianceProgramId);
  const isDuplicate = !isNew && location.pathname.includes("/duplicate");
  const [proposedComplianceProgram, setProposedComplianceProgram] =
    useState<SaveComplianceProgramMutationVariables>({
      appliesTo: ComplianceProgramAppliesTo.Organization,
      appliesToTeams: [],
      recurrence: ComplianceProgramRecurrence.None,
    });
  const [selectedProgramPeriodRange, setSelectedProgramPeriodRange] =
    useState<DateRangeType>(ExtraDateRangeEnum.none);
  const [
    availablePerformanceAssessmentGroups,
    setAvailablePerformanceAssessmentGroups,
  ] = useState<ComboboxGenericOption<number>[]>([]);
  const [availablePeerAssessmentGroups, setAvailablePeerAssessmentGroups] =
    useState<ComboboxGenericOption<number>[]>([]);
  const [
    availableManagerAssessmentGroups,
    setAvailableManagerAssessmentGroups,
  ] = useState<ComboboxGenericOption<number>[]>([]);
  const [availableTopicTemplates, setAvailableTopicTemplates] = useState<
    ComboboxGenericOption<number>[]
  >([]);
  const [appliesToTeams, setAppliesToTeams] = useState<
    { id: number; title: string }[]
  >([]);
  const [appliesToManagers, setAppliesToManagers] = useState<
    { id: number; name: string }[]
  >([]);
  const [appliesToUsers, setAppliesToUsers] = useState<
    { id: number; name: string }[]
  >([]);
  const [excludedUsers, setExcludedUsers] = useState<
    { id: number; name: string }[]
  >([]);
  const [canUpdateTemplates, setCanUpdateTemplates] = useState(isNew);

  const homeUrl = useMemo(
    () =>
      isNew || isDuplicate
        ? complianceProgramUrl
        : `${complianceProgramUrl}/${complianceProgramId}`,
    [complianceProgramId, isNew, isDuplicate]
  );

  const [
    createOrUpdateComplianceProgram,
    { loading: isSavingComplianceProgram },
  ] = useMutation<
    SaveComplianceProgramMutation,
    SaveComplianceProgramMutationVariables
  >(createOrUpdateComplianceProgramMutation);

  const { loading: isLoadingComplianceProgram } = useQuery<
    GetComplianceProgramQuery,
    GetComplianceProgramQueryVariables
  >(getComplianceProgramQuery, {
    variables: { complianceProgramId },
    skip: isNew,
    onError: onNotificationErrorHandler(),
    onCompleted: (response) => {
      if (!response.complianceProgram) {
        link.redirect(homeUrl);
      } else {
        const topicTemplates = assertEdgesNonNull(
          response.complianceProgram.requiredTopicTemplates
        );
        const assessmentGroups = compact([
          response.complianceProgram.performanceAssessmentGroup &&
            response.complianceProgram.performanceAssessmentGroup.id,
          response.complianceProgram.managerAssessmentGroup &&
            response.complianceProgram.managerAssessmentGroup.id,
          response.complianceProgram.peerAssessmentGroup &&
            response.complianceProgram.peerAssessmentGroup.id,
        ]);
        setAppliesToTeams(
          assertEdgesNonNull(response.complianceProgram.appliesToTeams)
        );
        setAppliesToManagers(
          assertEdgesNonNull(response.complianceProgram.appliesToManagers)
        );
        setAppliesToUsers(
          assertEdgesNonNull(response.complianceProgram.appliesToUsers)
        );
        setExcludedUsers(
          assertEdgesNonNull(response.complianceProgram.excludedUsers)
        );

        const dateRangeOptions = Object.values(PastOnlyDateRangeEnum).map(
          (dateRange) => {
            const dates = dateRangeToDateArray({
              range: dateRange,
              quarterStartMonth: organization.quarterStartMonth,
            });
            return {
              value: dateRange,
              startDate: dates[0],
              endDate: dates[1],
            };
          }
        );
        const programHasPeriod =
          response.complianceProgram.periodStartDate !== null &&
          response.complianceProgram.periodEndDate !== null;
        const selectedPeriodEnum = programHasPeriod
          ? dateRangeOptions.find(({ startDate, endDate }) => {
              return (
                startDate === response.complianceProgram?.periodStartDate &&
                endDate === response.complianceProgram?.periodEndDate
              );
            })
          : null;

        setProposedComplianceProgram({
          ...response.complianceProgram,
          state: isDuplicate
            ? ComplianceProgramState.Draft
            : response.complianceProgram.state,
          complianceProgramId: response.complianceProgram.id,
          topicTemplates: topicTemplates.map(({ id }) => ({ id })),
          assessmentGroups: assessmentGroups.map((id) => ({ id })),
          appliesTo: response.complianceProgram.appliesTo,
          appliesToTeams: appliesToTeams.map(({ id }) => id),
          appliesToManagers: appliesToManagers.map(({ id }) => id),
          appliesToUsers: appliesToUsers.map(({ id }) => id),
          excludedUsers: excludedUsers.map(({ id }) => id),
          periodStartDate: response.complianceProgram.periodStartDate,
          periodEndDate: response.complianceProgram.periodEndDate,
        });
        setSelectedProgramPeriodRange(
          selectedPeriodEnum
            ? selectedPeriodEnum.value
            : programHasPeriod
            ? ExtraDateRangeEnum.custom
            : ExtraDateRangeEnum.none
        );

        setCanUpdateTemplates(
          isDuplicate ||
            response.complianceProgram.state !==
              ComplianceProgramState.Published
        );
      }
    },
  });

  const {
    data: assessmentGroupsData,
    loading: isLoadingAvailableAssessmentGroups,
  } = useQuery<GetAssessmentGroupsQuery, GetAssessmentGroupsQueryVariables>(
    getAssessmentGroupsQuery,
    {
      variables: { organizationId: organization.id },
      onCompleted: (response) => {
        const groups = response.assessmentGroups
          ? assertEdgesNonNull(response.assessmentGroups)
          : [];
        setAvailablePerformanceAssessmentGroups(
          groups
            .filter((g) => g.assessmentType === AssessmentType.Performance)
            .map(({ id, title }) => ({ value: id, label: title }))
        );
        setAvailablePeerAssessmentGroups(
          groups
            .filter((g) => g.assessmentType === AssessmentType.Peer)
            .map(({ id, title }) => ({ value: id, label: title }))
        );
        setAvailableManagerAssessmentGroups(
          groups
            .filter((g) => g.assessmentType === AssessmentType.Manager)
            .map(({ id, title }) => ({ value: id, label: title }))
        );
      },
      onError: onNotificationErrorHandler(),
    }
  );

  const { loading: isLoadingAvailableTopicTemplates } = useQuery<
    GetTopicTemplatesQuery,
    GetTopicTemplatesQueryVariables
  >(getTemplatesQuery, {
    onCompleted: (response) => {
      const templates = response.topicTemplates
        ? assertEdgesNonNull(response.topicTemplates)
        : [];
      setAvailableTopicTemplates(
        templates.map(({ id, title }) => ({ value: id, label: title }))
      );
    },
    onError: onNotificationErrorHandler(),
  });

  const handleSaveAssessmentGroup = useCallback(
    (state: ComplianceProgramState) => {
      createOrUpdateComplianceProgram({
        variables: {
          ...proposedComplianceProgram,
          topicTemplates: canUpdateTemplates
            ? proposedComplianceProgram.topicTemplates
            : undefined,
          assessmentGroups: canUpdateTemplates
            ? proposedComplianceProgram.assessmentGroups
            : undefined,
          complianceProgramId:
            isNew || isDuplicate
              ? undefined
              : proposedComplianceProgram.complianceProgramId,
          organizationId: organization.id,
          state: state,
          appliesToTeams: appliesToTeams.map(({ id }) => id),
          appliesToManagers: appliesToManagers.map(({ id }) => id),
          appliesToUsers: appliesToUsers.map(({ id }) => id),
          excludedUsers: excludedUsers.map(({ id }) => id),
        },
        onError,
        refetchQueries: [getComplianceProgramsQuery],
        onCompleted: () => {
          successNotificationVar({
            title:
              state === ComplianceProgramState.Draft
                ? "Program saved for later"
                : "Program published",
          });
          link.redirect(homeUrl);
        },
      });
    },
    [
      appliesToManagers,
      appliesToTeams,
      appliesToUsers,
      excludedUsers,
      createOrUpdateComplianceProgram,
      homeUrl,
      link,
      onError,
      organization,
      proposedComplianceProgram,
      isNew,
      isDuplicate,
      canUpdateTemplates,
    ]
  );

  const selectedTopicTemplateId = useMemo(
    () =>
      proposedComplianceProgram.topicTemplates
        ? (proposedComplianceProgram.topicTemplates as Maybe<TopicTemplateComplianceRequirement>[])
        : [],
    [proposedComplianceProgram.topicTemplates]
  );

  const selectedAssessmentGroupId = useMemo(
    () =>
      proposedComplianceProgram.assessmentGroups
        ? (proposedComplianceProgram.assessmentGroups as Maybe<AssessmentGroupComplianceRequirement>[])
        : [],
    [proposedComplianceProgram.assessmentGroups]
  );

  const selectedPerformanceAssessmentGroup = useMemo(() => {
    const assessmentGroupId = selectedAssessmentGroupId.length
      ? availablePerformanceAssessmentGroups.find(({ value }) =>
          selectedAssessmentGroupId
            .map((g) => assertNonNull(g).id)
            .includes(value)
        )?.value ?? null
      : null;
    if (!assessmentGroupId) {
      return null;
    }
    const groups = assessmentGroupsData?.assessmentGroups
      ? assertEdgesNonNull(assessmentGroupsData.assessmentGroups)
      : [];
    return groups.find((group) => group.id === assessmentGroupId);
  }, [
    assessmentGroupsData,
    availablePerformanceAssessmentGroups,
    selectedAssessmentGroupId,
  ]);
  const selectedManagerAssessmentGroup = useMemo(() => {
    const assessmentGroupId = selectedAssessmentGroupId.length
      ? availableManagerAssessmentGroups.find(({ value }) =>
          selectedAssessmentGroupId
            .map((g) => assertNonNull(g).id)
            .includes(value)
        )?.value ?? null
      : null;
    if (!assessmentGroupId) {
      return null;
    }
    const groups = assessmentGroupsData?.assessmentGroups
      ? assertEdgesNonNull(assessmentGroupsData.assessmentGroups)
      : [];
    return groups.find((group) => group.id === assessmentGroupId);
  }, [
    assessmentGroupsData,
    availableManagerAssessmentGroups,
    selectedAssessmentGroupId,
  ]);
  const selectedPeerAssessmentGroup = useMemo(() => {
    const assessmentGroupId = selectedAssessmentGroupId.length
      ? availablePeerAssessmentGroups.find(({ value }) =>
          selectedAssessmentGroupId
            .map((g) => assertNonNull(g).id)
            .includes(value)
        )?.value ?? null
      : null;
    if (!assessmentGroupId) {
      return null;
    }
    const groups = assessmentGroupsData?.assessmentGroups
      ? assertEdgesNonNull(assessmentGroupsData.assessmentGroups)
      : [];
    return groups.find((group) => group.id === assessmentGroupId);
  }, [
    assessmentGroupsData,
    availablePeerAssessmentGroups,
    selectedAssessmentGroupId,
  ]);

  const handleChangeAssessmentGroup = useCallback(
    (assessmentType: AssessmentType) => (opt: ComboboxGenericOption<number>) => {
      const existingGroup =
        assessmentType === AssessmentType.Performance
          ? selectedPerformanceAssessmentGroup
          : assessmentType === AssessmentType.Manager
          ? selectedManagerAssessmentGroup
          : selectedPeerAssessmentGroup;

      const existingGroups =
        (proposedComplianceProgram.assessmentGroups as AssessmentGroupComplianceRequirement[]) ??
        [];
      setProposedComplianceProgram({
        ...proposedComplianceProgram,
        assessmentGroups: [
          ...existingGroups.filter(
            (group) => !existingGroup || group.id !== existingGroup.id
          ),
          { id: opt.value },
        ],
      });
    },
    [
      proposedComplianceProgram,
      selectedManagerAssessmentGroup,
      selectedPeerAssessmentGroup,
      selectedPerformanceAssessmentGroup,
    ]
  );

  const handleEnableRecurrence: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (evt) => {
        setProposedComplianceProgram({
          ...proposedComplianceProgram,
          recurrence: evt.target.checked
            ? ComplianceProgramRecurrence.Monthly
            : ComplianceProgramRecurrence.None,
        });
      },
      [proposedComplianceProgram]
    );

  const handleChangeRecurrence = useCallback(
    (opt: SelectOption<ComplianceProgramRecurrence>) => {
      setProposedComplianceProgram({
        ...proposedComplianceProgram,
        recurrence: opt.value,
      });
    },
    [proposedComplianceProgram]
  );

  const label = useLabel();
  const programPeriodOptions = useMemo(() => {
    const dateRangeOptions = Object.values(PastOnlyDateRangeEnum).map(
      (dateRange) => ({
        value: dateRange,
        label: label(dateRange, { capitalize: true }),
        description: dateRangeToDateArray({
          range: dateRange,
          quarterStartMonth: organization.quarterStartMonth,
        })
          .map((date) => moment(date).format("ll"))
          .join(" - "),
      })
    );
    return [
      {
        value: ExtraDateRangeEnum.none,
        label: "Not set",
      },
      ...dateRangeOptions,
      {
        value: ExtraDateRangeEnum.custom,
        label: "Custom date range",
      },
    ];
  }, [label, organization]);

  const handleChangeProgramPeriod = useCallback(
    (option: SelectOption<DateRangeType>) => {
      if (option.value === ExtraDateRangeEnum.none) {
        setProposedComplianceProgram({
          ...proposedComplianceProgram,
          periodStartDate: null,
          periodEndDate: null,
          removeProgramPeriod: true,
        });
      } else if (option.value !== ExtraDateRangeEnum.custom) {
        const dates = dateRangeToDateArray({
          range: option.value,
          quarterStartMonth: organization.quarterStartMonth,
        });
        setProposedComplianceProgram({
          ...proposedComplianceProgram,
          periodStartDate: dates[0],
          periodEndDate: dates[1],
          removeProgramPeriod: undefined,
        });
      }
      setSelectedProgramPeriodRange(option.value);
    },
    [organization, proposedComplianceProgram]
  );

  if (
    isLoadingComplianceProgram ||
    isLoadingAvailableAssessmentGroups ||
    isLoadingAvailableTopicTemplates
  ) {
    return (
      <div className="flex-1 flex justify-center p-10">
        <Loading>Loading compliance program..</Loading>
      </div>
    );
  }

  const canSaveTooltip =
    !proposedComplianceProgram.title ||
    proposedComplianceProgram.title.trim().length === 0
      ? "Please enter a title"
      : !proposedComplianceProgram.startDate
      ? "Please enter a start date"
      : !proposedComplianceProgram.dueDate
      ? "Please enter a due date"
      : selectedProgramPeriodRange === ExtraDateRangeEnum.custom &&
        (!proposedComplianceProgram.periodStartDate ||
          !proposedComplianceProgram.periodEndDate)
      ? "Please enter custom period dates"
      : null;

  return (
    <form className="p-6 flex-1" aria-label="Compliance program form">
      <div className="flex items-center justify-between mb-4">
        <div className="text-xl font-medium">
          {isNew ? "New" : isDuplicate ? "Duplicate" : "Edit"} program
        </div>
        <div className="flex justify-end items-center gap-2 sm:gap-4">
          <Button
            to={homeUrl}
            theme={buttonTheme.text}
            disabled={isSavingComplianceProgram}
          >
            Discard changes
          </Button>
          {proposedComplianceProgram.state !==
            ComplianceProgramState.Published && (
            <Button
              type="button"
              onClick={() =>
                handleSaveAssessmentGroup(ComplianceProgramState.Draft)
              }
              tooltip={canSaveTooltip}
              disabled={!!canSaveTooltip || isSavingComplianceProgram}
              theme={buttonTheme.lightBlue}
            >
              {`${isSavingComplianceProgram ? "Saving" : "Save"} for later`}
            </Button>
          )}
          <Button
            type="button"
            onClick={() =>
              handleSaveAssessmentGroup(ComplianceProgramState.Published)
            }
            tooltip={canSaveTooltip}
            disabled={!!canSaveTooltip || isSavingComplianceProgram}
            theme={buttonTheme.primary}
          >
            {proposedComplianceProgram.state !==
            ComplianceProgramState.Published
              ? "Save and publish"
              : "Save changes"}
          </Button>
        </div>
      </div>
      <div className="sm:flex">
        <div className="flex flex-col text-sm gap-2 w-full">
          <div className="w-96 flex flex-col gap-2">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Title
            </div>
            <Input
              aria-label="Compliance program title input"
              value={proposedComplianceProgram.title ?? ""}
              onChange={(e) =>
                setProposedComplianceProgram({
                  ...proposedComplianceProgram,
                  title: e.target.value,
                })
              }
            />
          </div>
          <div className="w-96 flex flex-col gap-2">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Start date
            </div>
            <DatePicker
              selected={
                proposedComplianceProgram.startDate
                  ? moment(proposedComplianceProgram.startDate).toDate()
                  : null
              }
              onChange={(date) =>
                setProposedComplianceProgram({
                  ...proposedComplianceProgram,
                  startDate: date ? moment(date).format("YYYY-MM-DD") : null,
                })
              }
              dateFormat="MMM d, yyyy"
              ariaLabelledBy="Compliance program start date"
              className="px-4 py-2 block w-full sm:text-sm shadow-inner border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div className="w-96 flex flex-col gap-2">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Due date
            </div>
            <DatePicker
              selected={
                proposedComplianceProgram.dueDate
                  ? moment(proposedComplianceProgram.dueDate).toDate()
                  : null
              }
              onChange={(date) =>
                setProposedComplianceProgram({
                  ...proposedComplianceProgram,
                  dueDate: date ? moment(date).format("YYYY-MM-DD") : null,
                })
              }
              dateFormat="MMM d, yyyy"
              ariaLabelledBy="Compliance program due date"
              className="px-4 py-2 block w-full sm:text-sm shadow-inner border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div className="w-96 flex items-center gap-4">
            <div className="h-12 flex items-center gap-2">
              <input
                type="checkbox"
                checked={
                  proposedComplianceProgram.recurrence !==
                  ComplianceProgramRecurrence.None
                }
                onChange={handleEnableRecurrence}
              />{" "}
              Repeat
            </div>
            {proposedComplianceProgram.recurrence !==
              ComplianceProgramRecurrence.None && (
              <Select
                className="flex-1"
                value={assertNonNull(proposedComplianceProgram.recurrence)}
                options={[
                  {
                    value: ComplianceProgramRecurrence.Monthly,
                    label: "Monthly",
                  },
                  {
                    value: ComplianceProgramRecurrence.Quarterly,
                    label: "Quarterly",
                  },
                  {
                    value: ComplianceProgramRecurrence.Yearly,
                    label: "Yearly",
                  },
                ]}
                onChange={handleChangeRecurrence}
                aria-label="Compliance program recurrence select"
              />
            )}
          </div>
          <div className="mt-2 border-t pt-2 w-96 flex flex-col gap-2">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Progam period
            </div>

            <Select<DateRangeType>
              onChange={handleChangeProgramPeriod}
              options={programPeriodOptions}
              value={selectedProgramPeriodRange}
            />

            {selectedProgramPeriodRange === ExtraDateRangeEnum.custom && (
              <>
                <div className="text-gray-500 text-xs uppercase font-semibold">
                  Period start date
                </div>
                <DatePicker
                  selected={
                    proposedComplianceProgram.periodStartDate
                      ? moment(
                          proposedComplianceProgram.periodStartDate
                        ).toDate()
                      : null
                  }
                  onChange={(date) =>
                    setProposedComplianceProgram({
                      ...proposedComplianceProgram,
                      periodStartDate: date
                        ? moment(date).format("YYYY-MM-DD")
                        : null,
                      removeProgramPeriod: undefined,
                    })
                  }
                  dateFormat="MMM d, yyyy"
                  ariaLabelledBy="Compliance program period start date"
                  className="px-4 py-2 block w-full sm:text-sm shadow-inner border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                />
                <div className="text-gray-500 text-xs uppercase font-semibold">
                  Period end date
                </div>
                <DatePicker
                  selected={
                    proposedComplianceProgram.periodEndDate
                      ? moment(proposedComplianceProgram.periodEndDate).toDate()
                      : null
                  }
                  onChange={(date) =>
                    setProposedComplianceProgram({
                      ...proposedComplianceProgram,
                      periodEndDate: date
                        ? moment(date).format("YYYY-MM-DD")
                        : null,
                      removeProgramPeriod: undefined,
                    })
                  }
                  dateFormat="MMM d, yyyy"
                  ariaLabelledBy="Compliance program period end date"
                  className="px-4 py-2 block w-full sm:text-sm shadow-inner border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                />
              </>
            )}
          </div>

          <div className="mt-2 border-t pt-2 grid grid-cols-6 gap-6">
            <div className="col-span-3 text-gray-500 text-xs uppercase font-semibold">
              Assessments
            </div>
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Providers
            </div>
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Anonymity
            </div>
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Delivery
            </div>
            <div className="col-span-3 flex items-center gap-2">
              <div className="text-gray-500 text-xs w-18">Performance</div>
              <ComboboxGeneric
                className="flex-1"
                aria-label="Compliance program performance assessment template"
                options={availablePerformanceAssessmentGroups}
                clearable
                disabled={!canUpdateTemplates}
                onClearValue={() => {
                  const existingGroups =
                    proposedComplianceProgram.assessmentGroups as AssessmentGroupComplianceRequirement[];
                  setProposedComplianceProgram({
                    ...proposedComplianceProgram,
                    assessmentGroups: existingGroups.filter(
                      (g) =>
                        !availablePerformanceAssessmentGroups
                          .map((g) => g.value)
                          .includes(g.id)
                    ),
                  });
                }}
                onChangeValue={handleChangeAssessmentGroup(
                  AssessmentType.Performance
                )}
                value={
                  selectedAssessmentGroupId.length
                    ? availablePerformanceAssessmentGroups.find(({ value }) =>
                        selectedAssessmentGroupId
                          .map((g) => assertNonNull(g).id)
                          .includes(value)
                      ) ?? null
                    : null
                }
              />
            </div>
            <div className="text-gray-500 self-center">
              {selectedPerformanceAssessmentGroup
                ? capitalize(
                    removeUnderscores(
                      selectedPerformanceAssessmentGroup.providers
                    )
                  )
                : "-"}
            </div>
            <div className="text-gray-500 self-center">
              {selectedPerformanceAssessmentGroup
                ? capitalize(
                    removeUnderscores(
                      selectedPerformanceAssessmentGroup.anonymity
                    )
                  )
                : "-"}
            </div>
            <div className="text-gray-500 self-center">
              {selectedPerformanceAssessmentGroup
                ? capitalize(
                    removeUnderscores(
                      selectedPerformanceAssessmentGroup.delivery
                    )
                  )
                : "-"}
            </div>

            <div className="col-span-3 flex items-center gap-2">
              <div className="text-gray-500 text-xs w-18">Manager</div>
              <ComboboxGeneric
                className="flex-1"
                aria-label="Compliance program manager assessment template"
                options={availableManagerAssessmentGroups}
                clearable
                disabled={!canUpdateTemplates}
                onClearValue={() => {
                  const existingGroups =
                    proposedComplianceProgram.assessmentGroups as AssessmentGroupComplianceRequirement[];
                  setProposedComplianceProgram({
                    ...proposedComplianceProgram,
                    assessmentGroups: existingGroups.filter(
                      (g) =>
                        !availableManagerAssessmentGroups
                          .map((g) => g.value)
                          .includes(g.id)
                    ),
                  });
                }}
                onChangeValue={handleChangeAssessmentGroup(
                  AssessmentType.Manager
                )}
                value={
                  selectedAssessmentGroupId.length
                    ? availableManagerAssessmentGroups.find(({ value }) =>
                        selectedAssessmentGroupId
                          .map((g) => assertNonNull(g).id)
                          .includes(value)
                      ) ?? null
                    : null
                }
              />
            </div>
            <div className="text-gray-500 self-center">
              {selectedManagerAssessmentGroup
                ? capitalize(
                    removeUnderscores(selectedManagerAssessmentGroup.providers)
                  )
                : "-"}
            </div>
            <div className="text-gray-500 self-center">
              {selectedManagerAssessmentGroup
                ? capitalize(
                    removeUnderscores(selectedManagerAssessmentGroup.anonymity)
                  )
                : "-"}
            </div>
            <div className="text-gray-500 self-center">
              {selectedManagerAssessmentGroup
                ? capitalize(
                    removeUnderscores(selectedManagerAssessmentGroup.delivery)
                  )
                : "-"}
            </div>

            <div className="col-span-3 flex items-center gap-2">
              <div className="text-gray-500 text-xs w-18">Peer</div>
              <ComboboxGeneric
                className="flex-1"
                aria-label="Compliance program peer assessment template"
                options={availablePeerAssessmentGroups}
                clearable
                disabled={!canUpdateTemplates}
                onClearValue={() => {
                  const existingGroups =
                    proposedComplianceProgram.assessmentGroups as AssessmentGroupComplianceRequirement[];
                  setProposedComplianceProgram({
                    ...proposedComplianceProgram,
                    assessmentGroups: existingGroups.filter(
                      (g) =>
                        !availablePeerAssessmentGroups
                          .map((g) => g.value)
                          .includes(g.id)
                    ),
                  });
                }}
                onChangeValue={handleChangeAssessmentGroup(AssessmentType.Peer)}
                value={
                  selectedAssessmentGroupId.length
                    ? availablePeerAssessmentGroups.find(({ value }) =>
                        selectedAssessmentGroupId
                          .map((g) => assertNonNull(g).id)
                          .includes(value)
                      ) ?? null
                    : null
                }
              />
            </div>
            <div className="text-gray-500 self-center">
              {selectedPeerAssessmentGroup
                ? capitalize(
                    removeUnderscores(selectedPeerAssessmentGroup.providers)
                  )
                : "-"}
            </div>
            <div className="text-gray-500 self-center">
              {selectedPeerAssessmentGroup
                ? capitalize(
                    removeUnderscores(selectedPeerAssessmentGroup.anonymity)
                  )
                : "-"}
            </div>
            <div className="text-gray-500 self-center">
              {selectedPeerAssessmentGroup
                ? capitalize(
                    removeUnderscores(selectedPeerAssessmentGroup.delivery)
                  )
                : "-"}
            </div>
          </div>

          <div className="flex flex-col gap-2 mt-2 border-t border-b py-2">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Meeting template
            </div>
            <div className="max-w-sm">
              <ComboboxGeneric
                options={availableTopicTemplates}
                clearable
                disabled={!canUpdateTemplates}
                onClearValue={() =>
                  setProposedComplianceProgram({
                    ...proposedComplianceProgram,
                    topicTemplates: [],
                  })
                }
                onChangeValue={(opt) =>
                  setProposedComplianceProgram({
                    ...proposedComplianceProgram,
                    topicTemplates: [{ id: opt.value }],
                  })
                }
                value={
                  selectedTopicTemplateId.length
                    ? availableTopicTemplates.find(
                        ({ value }) =>
                          value === assertNonNull(selectedTopicTemplateId[0]).id
                      ) ?? null
                    : null
                }
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Applies to
            </div>
            <div className="max-w-sm">
              <ComboboxGeneric
                options={appliesToOptions}
                disabled={!canUpdateTemplates}
                onChangeValue={(opt) =>
                  setProposedComplianceProgram({
                    ...proposedComplianceProgram,
                    appliesTo: opt.value,
                  })
                }
                value={assertNonNull(
                  appliesToOptions.find(
                    ({ value }) => value === proposedComplianceProgram.appliesTo
                  )
                )}
              />
            </div>
          </div>
          {proposedComplianceProgram.appliesTo ===
            ComplianceProgramAppliesTo.Departments && (
            <div className="flex flex-col gap-2">
              <div className="text-gray-500 text-xs uppercase font-semibold">
                Departments
              </div>
              <div className="max-w-sm">
                <TeamPicker
                  canChange={canUpdateTemplates}
                  teams={appliesToTeams}
                  onAddTeam={(team) => {
                    setAppliesToTeams(appliesToTeams.concat(team));
                  }}
                  onRemoveTeam={(team) => {
                    setAppliesToTeams(
                      appliesToTeams.filter((t) => t.id !== team.id)
                    );
                  }}
                />
              </div>
            </div>
          )}
          {proposedComplianceProgram.appliesTo ===
            ComplianceProgramAppliesTo.Managers && (
            <div className="flex flex-col gap-2">
              <div className="text-gray-500 text-xs uppercase font-semibold">
                Managers
              </div>
              <div className="max-w-sm">
                <RecipientForm
                  canChange={canUpdateTemplates}
                  showCurrentUser
                  recipients={appliesToManagers}
                  onAddRecipient={(user) =>
                    setAppliesToManagers([...appliesToManagers, user])
                  }
                  onRemoveRecipient={(user) =>
                    setAppliesToManagers(
                      appliesToManagers.filter((u) => u.id !== user.id)
                    )
                  }
                />
              </div>
            </div>
          )}
          {proposedComplianceProgram.appliesTo ===
            ComplianceProgramAppliesTo.Users && (
            <div className="flex flex-col gap-2">
              <div className="text-gray-500 text-xs uppercase font-semibold">
                Users
              </div>
              <div className="max-w-sm">
                <RecipientForm
                  canChange={canUpdateTemplates}
                  showCurrentUser
                  recipients={appliesToUsers}
                  onAddRecipient={(user) =>
                    setAppliesToUsers([...appliesToUsers, user])
                  }
                  onRemoveRecipient={(user) =>
                    setAppliesToUsers(
                      appliesToUsers.filter((u) => u.id !== user.id)
                    )
                  }
                />
              </div>
            </div>
          )}
          <div className="flex flex-col gap-2">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Excluded users
            </div>
            <div className="max-w-sm">
              <RecipientForm
                canChange={canUpdateTemplates}
                showCurrentUser
                recipients={excludedUsers}
                onAddRecipient={(user) =>
                  setExcludedUsers([...excludedUsers, user])
                }
                onRemoveRecipient={(user) =>
                  setExcludedUsers(
                    excludedUsers.filter((u) => u.id !== user.id)
                  )
                }
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Exclude users hired after
            </div>
            <div className="flex items-center gap-2">
              <DatePicker
                wrapperClassName="w-96"
                disabled={!canUpdateTemplates}
                selected={
                  proposedComplianceProgram.excludedHireDateAfter
                    ? moment(
                        proposedComplianceProgram.excludedHireDateAfter
                      ).toDate()
                    : null
                }
                onChange={(date) =>
                  setProposedComplianceProgram({
                    ...proposedComplianceProgram,
                    excludedHireDateAfter: date
                      ? moment(date).format("YYYY-MM-DD")
                      : null,
                    removeExcludedHireDateAfter: undefined,
                  })
                }
                dateFormat="MMM d, yyyy"
                ariaLabelledBy="Compliance program exclude hire date after"
                className="px-4 py-2 block w-full sm:text-sm shadow-inner border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
              />
              {proposedComplianceProgram.excludedHireDateAfter && (
                <Button
                  className="text-sm"
                  onClick={() =>
                    setProposedComplianceProgram({
                      ...proposedComplianceProgram,
                      excludedHireDateAfter: undefined,
                      removeExcludedHireDateAfter: true,
                    })
                  }
                  theme={buttonTheme.text}
                  disabled={isSavingComplianceProgram}
                >
                  Clear
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ComplianceProgramEdit;
