import { gql } from "@apollo/client";

export default gql`
  query getChatSessionMessagesQuery($chatSessionId: Int!) {
    chatSession(chatSessionId: $chatSessionId) {
      id
      messages {
        edges {
          node {
            id
            content
            role
            autogenerated
          }
        }
      }
    }
  }
`;
