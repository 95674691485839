import { gql } from "@apollo/client";

export default gql`
  query getFlowQuery($flowId: Int!) {
    flow(flowId: $flowId) {
      id
      title
      public
      participantEmailPatterns
      canDelete {
        permission
      }
      organization {
        id
      }
      meetingGroups {
        edges {
          node {
            id
            title
          }
        }
      }
    }
  }
`;
