import {
  Combobox,
  Popover,
  PopoverButtonProps,
  Portal,
} from "@headlessui/react";
import {
  CheckIcon,
  SearchIcon,
  SelectorIcon,
  XIcon,
} from "@heroicons/react/outline";
import { compact } from "lodash";
import moment from "moment";
import { MouseEventHandler, useState } from "react";
import { usePopper } from "react-popper";
import { rrulestr } from "rrule";

import Loading from "@components/loading/loading";
import {
  classNames,
  inputBorderClassName,
  inputFocusClassName,
} from "@helpers/css";

const PopoverPanel = ({
  close,
  width,
  onChange,
  disabled,
  loading,
  validOptions,
  value,
  query,
  placeholder,
  onChangeQuery,
}: {
  close: any;
  width: any;
  onChange: any;
  disabled: any;
  loading: any;
  validOptions: any;
  value: any;
  query: any;
  placeholder: any;
  onChangeQuery: any;
}) => (
  <Combobox onChange={onChange(close)} disabled={disabled}>
    <div className={`relative w-${width}`}>
      <div className="relative w-full cursor-default">
        <Combobox.Input
          autoFocus
          onChange={(event) => onChangeQuery(event.target.value)}
          placeholder={placeholder}
          aria-label="Meeting combobox search input"
          className="relative w-full pl-11 pr-9 py-2 text-left cursor-default sm:text-sm border-b focus:outline-0 focus:ring-0 bg-transparent"
        />
        <span className="absolute inset-y-0 left-0 -top-1 flex items-center pl-4">
          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
        {loading && (
          <Loading
            mini
            size="4"
            className="absolute inset-y-0 right-2 top-2.5 flex items-center"
          />
        )}
      </div>
      {validOptions.length > 0 && (
        <Combobox.Options
          static
          className="text-sm overflow-auto focus:outline-none overflow-y-auto max-h-54"
        >
          {validOptions.map((meeting: any, i: number) => (
            <Combobox.Option
              key={meeting.id}
              value={meeting}
              className={({ active }) =>
                classNames(
                  active ? "text-white bg-blue-600" : "text-gray-800",
                  "cursor-default select-none relative py-2 pl-4 pr-9",
                  validOptions.length - 1 === i && "rounded-b-md"
                )
              }
            >
              {({ active }) => (
                <div className="flex justify-between items-center gap-2 pr-5">
                  <div className="flex-1 flex flex-col">
                    <div className="flex gap-2 text-ellipsis overflow-hidden">
                      <div>{meeting.title}</div>
                    </div>
                    {meeting.meetingGroup?.recurrenceRule ? (
                      <div className="-mt-1 text-2xs opacity-70 tracking-tight">
                        {rrulestr(meeting.meetingGroup.recurrenceRule).toText()}
                      </div>
                    ) : (
                      meeting.startDatetime && (
                        <div className="-mt-1 text-2xs opacity-70">
                          {moment(meeting.startDatetime).format("LLLL")}
                        </div>
                      )
                    )}
                  </div>
                  {value.id === meeting.id && (
                    <span
                      className={classNames(
                        active ? "text-white" : "text-indigo-600",
                        "absolute inset-y-0 right-0 flex items-center pr-4"
                      )}
                    >
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  )}
                </div>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      )}
      {!loading && query !== "" && validOptions.length === 0 && (
        <div className="py-14 px-4 text-center sm:px-14">
          <p className="mt-4 text-sm text-gray-900">No results.</p>
        </div>
      )}
    </div>
  </Combobox>
);

const MeetingCombobox = ({
  disabled,
  width,
  value,
  options = [],
  loading,
  query,
  clearable = false,
  portal = true,
  onClearValue,
  onChangeValue,
  onChangeQuery,
  onClickButton,
  name = "meeting-combobox",
  placeholder = "Search meetings...",
  className = "",
  children,
  ...props
}: Omit<PopoverButtonProps<"button">, "value"> & {
  disabled?: any;
  width: any;
  value: any;
  options: any;
  loading: any;
  query: any;
  clearable: boolean;
  portal: boolean;
  onClearValue: any;
  onChangeValue: any;
  onChangeQuery: any;
  onClickButton: MouseEventHandler<HTMLButtonElement>;
  name?: string;
  placeholder: string;
  className?: string;
  children?: any;
}) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
  });
  const filteredOptions =
    query === ""
      ? options
      : options.filter((option: any) =>
          option.title.toLowerCase().includes(query.toLowerCase())
        );
  const validOptions = compact(filteredOptions);

  const handleChange = (close: any) => (option: any) => {
    close();
    onChangeValue(option, { name });
  };

  return (
    <Popover className="relative" {...props}>
      {children ? (
        children({
          clearable,
          onClearValue,
          value,
          onClickButton,
          setReferenceElement,
        })
      ) : (
        <div
          className={classNames(
            "flex justify-between bg-white rounded-md",
            inputBorderClassName,
            inputFocusClassName,
            className
          )}
        >
          <Popover.Button
            className={classNames(
              "pl-3 pr-2 py-2 flex items-center gap-2 w-full text-sm text-gray-800",
              "min-w-0" // prevent child el with flex-1 to overflow and will add ellipsis in that case
            )}
            onClick={onClickButton}
            ref={setReferenceElement}
          >
            <div className="flex-1 text-left text-ellipsis overflow-hidden">
              {value.name}
            </div>
            {!clearable && <SelectorIcon className="h-4 w-4" />}
          </Popover.Button>
          {clearable && (
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClearValue();
              }}
              className="px-1 py-1 flex items-center text-gray-600"
              aria-label="Combobox clear button"
            >
              <span className="px-1 py-1 rounded hover:bg-gray-100">
                <XIcon className="h-4 w-4" />
              </span>
            </button>
          )}
        </div>
      )}
      {portal ? (
        <Portal>
          <Popover.Panel
            className="absolute z-dropdown bg-white border rounded-md shadow-md"
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            aria-label="Meeting combobox popover"
          >
            {({ close }) => (
              <PopoverPanel
                key="popoverpanel"
                close={close}
                width={width}
                onChange={handleChange}
                disabled={disabled}
                loading={loading}
                validOptions={validOptions}
                value={value}
                query={query}
                placeholder={placeholder}
                onChangeQuery={onChangeQuery}
              />
            )}
          </Popover.Panel>
        </Portal>
      ) : (
        <Popover.Panel
          className="absolute z-dropdown bg-white border rounded-md shadow-md"
          aria-label="Meeting combobox popover"
        >
          {({ close }) => (
            <PopoverPanel
              key="popoverpanel"
              close={close}
              width={width}
              onChange={handleChange}
              disabled={disabled}
              loading={loading}
              validOptions={validOptions}
              value={value}
              query={query}
              placeholder={placeholder}
              onChangeQuery={onChangeQuery}
            />
          )}
        </Popover.Panel>
      )}
    </Popover>
  );
};

export default MeetingCombobox;
