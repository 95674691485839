import { gql } from "@apollo/client";

import MeetingViewMeetingNodeFragment from "../../../graphql/meeting-view-meeting-node-fragment";

export default gql`
  ${MeetingViewMeetingNodeFragment}
  query getFlowMeetingQuery(
    $meetingId: Int
    $flowId: Int!
    $now: DateTime!
    $hasMeetingId: Boolean = false
  ) {
    flow(flowId: $flowId) {
      id
      title
      public
      lastMeetings: meetings(
        last: 1
        orderBy: "start_datetime"
        status: confirmed
        ignored: false
      ) @skip(if: $hasMeetingId) {
        edges {
          node {
            ...MeetingViewMeetingNodeFragment
          }
        }
      }
      nextMeetings: meetings(
        first: 1
        endDatetime_Gte: $now
        status: confirmed
        ignored: false
      ) @skip(if: $hasMeetingId) {
        edges {
          node {
            ...MeetingViewMeetingNodeFragment
          }
        }
      }
    }
    meeting(meetingId: $meetingId) @include(if: $hasMeetingId) {
      ...MeetingViewMeetingNodeFragment
    }
  }
`;
