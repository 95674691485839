import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  KpiSummaryPeriod,
  SaveUserUiPreferenceMutationMutation,
  SaveUserUiPreferenceMutationMutationVariables,
} from "types/graphql-schema";

import { currentUserVar } from "@cache/cache";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import saveUserUiPreferenceMutation from "./graphql/save-user-ui-preference-mutation";

export enum GoalOverviewGoalRoleFilterEnum {
  both = "both",
  owners = "owning",
  contributors = "contributing",
}

export enum GoalOverviewGoalDisplayEnum {
  compact = "compact",
  details = "details",
}

export enum MeetingsViewEnum {
  overview = "overview",
  calendar = "calendar",
}

export enum CalendarViewEnum {
  week = "week",
  month = "month",
}

export enum MeetingSummaryLengthButtonToggle {
  short = "Short",
  medium = "Medium",
  long = "Long",
}

export type UiPreferenceCache = {
  // dashboard
  dashboardExpanded: boolean;
  dashboardFeedbackReceivedExpanded: boolean;
  dashboardFeedbackGivenExpanded: boolean;
  dashboardRecognitionReceivedExpanded: boolean;
  dashboardRecognitionGivenExpanded: boolean;
  dashboardPeopleTableManagersExpanded: boolean;
  dashboardPeopleTableReportsExpanded: boolean;
  dashboardPeopleTableDirectTeamExpanded: boolean;
  dashboardPeopleTableFavouritesExpanded: boolean;
  dashboardPersonalGoalsExpanded: boolean;
  dashboardTeamGoalsExpanded: boolean;
  dashboardOrganizationGoalsExpanded: boolean;
  dashboardInsightsExpanded: boolean;
  dashboardRequirementsExpanded: boolean;
  dashboardActionItemsExpanded: [number] | null;
  dashboardRequestsExpanded: boolean;

  // Artifact meeting collapsible state
  meetingActionItemsExpanded: any;
  oneononeActionItemsExpanded: any;
  meetingOpenActionItemsExpanded: boolean;
  meetingClosedActionItemsExpanded: boolean;
  meetingOpenGoalsExpanded: boolean;
  meetingClosedGoalsExpanded: boolean;
  meetingDraftGoalsExpanded: boolean;
  meetingReceivedRecognitionsExpanded: boolean;
  meetingGivenRecognitionsExpanded: boolean;
  meetingReceivedFeedbackExpanded: boolean;
  meetingGivenFeedbackExpanded: boolean;

  // Artifact meeting sidebar filter
  meetingActionItemsJustMe: boolean;
  oneononeActionItemsJustMe: boolean;
  meetingActionItemsJustMeeting: boolean;
  oneononeActionItemsJustMeeting: boolean;
  meetingGoalsJustMe: boolean;
  oneononeGoalsJustMe: boolean;
  meetingGoalsJustMeeting: boolean;
  oneononeGoalsJustMeeting: boolean;
  meetingRecognitionsJustMe: boolean;
  oneononeRecognitionsJustMe: boolean;
  oneononeRecognitionsJustMeeting: boolean;
  meetingRecognitionsJustMeeting: boolean;
  oneononeFeedbackOnlyParticipants: boolean;
  oneononePerformanceAssessments: boolean;
  oneononePeerAssessments: boolean;
  oneononeManagerAssessments: boolean;
  meetingSummaryLength: MeetingSummaryLengthButtonToggle;

  // Meetings/calendars
  calendarFilters: any;
  meetingsView: MeetingsViewEnum;
  calendarView: CalendarViewEnum;

  toggledTemplateGroups: any;
  meetingInsightsExpanded: any;
  explorerCollapsedGroupIds: any;
  kpiSummaryTableCollapsedIds: any;
  kpiListSummaryPeriod: KpiSummaryPeriod;

  objectiveAlignmentExpandedIds: number[];
  objectiveAlignmentIsShowingKeyResults: boolean;
  objectiveAlignmentIsShowingUnaligned: boolean;
  objectiveAlignmentIsShowingClosed: boolean;
  goalOverviewGoalDisplay: GoalOverviewGoalDisplayEnum;
  goalOverviewGoalRoleFilter: GoalOverviewGoalRoleFilterEnum;

  programsShowOnlyMyProgramsFlag: boolean;

  assessmentOwnedGoalsExpanded: boolean;
  assessmentContributingGoalsExpanded: boolean;
};

export const formatUiPreferenceCache = (
  uiPreferenceCache: UiPreferenceCache,
  update: Partial<UiPreferenceCache>
): UiPreferenceCache => {
  const defaultUiPreferenceCache = {
    // meetings page
    calendarFilters: [],
    meetingsView: MeetingsViewEnum.calendar,
    calendarView: CalendarViewEnum.week,

    // dashboard
    dashboardActionItemsExpanded: null,
    dashboardFeedbackReceivedExpanded: false,
    dashboardFeedbackGivenExpanded: false,
    dashboardRecognitionReceivedExpanded: true,
    dashboardRecognitionGivenExpanded: true,
    dashboardPeopleTableManagersExpanded: true,
    dashboardPeopleTableReportsExpanded: true,
    dashboardPeopleTableDirectTeamExpanded: true,
    dashboardPeopleTableFavouritesExpanded: true,
    dashboardExpanded: false,
    dashboardInsightsExpanded: true,
    dashboardRequirementsExpanded: true,
    dashboardOrganizationGoalsExpanded: true,
    dashboardPersonalGoalsExpanded: true,
    dashboardTeamGoalsExpanded: true,
    dashboardRequestsExpanded: true,

    // goalOverview
    goalOverviewGoalDisplay: GoalOverviewGoalDisplayEnum.compact,
    goalOverviewGoalRoleFilter: GoalOverviewGoalRoleFilterEnum.both,

    // explorer
    explorerCollapsedGroupIds: [],

    // kpis
    kpiListSummaryPeriod: KpiSummaryPeriod.Monthly,
    kpiSummaryTableCollapsedIds: [],

    // meetings
    meetingActionItemsExpanded: null,
    meetingClosedActionItemsExpanded: true,
    meetingClosedGoalsExpanded: true,
    meetingDraftGoalsExpanded: true,
    meetingInsightsExpanded: true,
    meetingOpenActionItemsExpanded: true,
    meetingOpenGoalsExpanded: true,
    oneononeActionItemsExpanded: false,
    toggledTemplateGroups: [],
    meetingReceivedRecognitionsExpanded: true,
    meetingGivenRecognitionsExpanded: false,
    meetingReceivedFeedbackExpanded: false,
    meetingGivenFeedbackExpanded: false,
    meetingSummaryLength: MeetingSummaryLengthButtonToggle.short,

    // meeting filter context
    oneononeActionItemsJustMeeting: true,
    meetingActionItemsJustMeeting: true,
    oneononeActionItemsJustMe: false,
    meetingActionItemsJustMe: false,
    meetingGoalsJustMe: false,
    oneononeGoalsJustMe: false,
    oneononeGoalsJustMeeting: true,
    meetingGoalsJustMeeting: true,
    meetingRecognitionsJustMe: false,
    oneononeRecognitionsJustMe: false,
    oneononeRecognitionsJustMeeting: true,
    meetingRecognitionsJustMeeting: true,
    oneononeFeedbackOnlyParticipants: false,
    oneononePerformanceAssessments: true,
    oneononePeerAssessments: false,
    oneononeManagerAssessments: false,

    // obejctiveAlignment
    objectiveAlignmentExpandedIds: [],
    objectiveAlignmentIsShowingKeyResults: false,
    objectiveAlignmentIsShowingUnaligned: false,
    objectiveAlignmentIsShowingClosed: false,

    // programs
    programsShowOnlyMyProgramsFlag: false,

    // assessments
    assessmentOwnedGoalsExpanded: true,
    assessmentContributingGoalsExpanded: true,
  };
  return {
    ...defaultUiPreferenceCache,
    ...uiPreferenceCache,
    ...update,
  };
};

export const getUiPreferenceCache = (): UiPreferenceCache => {
  const currentUser = currentUserVar();
  const uiPreferenceCache = formatUiPreferenceCache(
    JSON.parse(currentUser.uiPreferenceCache),
    {}
  );
  return uiPreferenceCache;
};

const useUiPreferenceCache = () => {
  const currentUser = currentUserVar();
  const uiPreferenceCache = getUiPreferenceCache();
  const [saveUiUserPrefs] = useMutation<
    SaveUserUiPreferenceMutationMutation,
    SaveUserUiPreferenceMutationMutationVariables
  >(saveUserUiPreferenceMutation);

  const saveUiPreference = useCallback(
    (config: Partial<UiPreferenceCache>) => {
      const newUiPreferenceCache = JSON.stringify(
        formatUiPreferenceCache(uiPreferenceCache, config)
      );
      saveUiUserPrefs({
        variables: {
          userId: currentUser.id,
          uiPreferenceCache: newUiPreferenceCache,
        },
        onError: onNotificationErrorHandler(),
        optimisticResponse: {
          updateUser: {
            user: {
              ...currentUser,
              uiPreferenceCache: newUiPreferenceCache,
            },
            __typename: "UpdateUserMutation",
          },
          __typename: "Mutation",
        },
      });
    },
    [uiPreferenceCache, currentUser, saveUiUserPrefs]
  );

  return { uiPreferenceCache, saveUiPreference };
};

export default useUiPreferenceCache;
