import { XIcon } from "@heroicons/react/outline";

import Error from "@components/error/error";
import Link from "@components/link/link";

const TopicSidebarError = ({ error, focusRef, onClose }) => {
  let title = "The topic could not be loaded.";
  let description = null;
  if (
    error?.message.includes(
      "401 Client Error: Unauthorized for url: https://api.github.com/graphql"
    )
  ) {
    title = "We are unable to communicate with GitHub.";
    description = (
      <div>
        Please verify that:
        <br />
        1. You have granted access to the "{import.meta.env.VITE_APP_NAME}" app
        in{" "}
        <a
          className="underline"
          href="https://github.com/settings/applications"
        >
          GitHub
        </a>
        .<br />
        2. The{" "}
        <Link className="underline" to="/account/integrations">
          GitHub integration
        </Link>{" "}
        is connected in "{import.meta.env.VITE_APP_NAME}".
      </div>
    );
  }

  return (
    <div className="-mt-3" aria-label="Error sidebar topic">
      <div className="flex justify-end">
        <button
          type="button"
          className="ml-3 bg-white rounded p-1 text-gray-400 hover:text-gray-500 hover:bg-gray-100"
          aria-label="Close topic sidebar"
          onClick={onClose}
          ref={focusRef}
        >
          <span className="sr-only">Close topic sidebar</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="pt-2">
        <Error title={title} description={description} />
      </div>
    </div>
  );
};

export default TopicSidebarError;
