import { Popover } from "@headlessui/react";
import {
  AnnotationIcon,
  CalendarIcon,
  DocumentDuplicateIcon,
  HomeIcon,
  SearchIcon,
  StarIcon,
  TrendingUpIcon,
  ViewGridIcon,
} from "@heroicons/react/outline";
import compact from "lodash/compact";
import { MouseEvent, MouseEventHandler, SVGProps, useState } from "react";
import { MdOutlineAssessment, MdOutlineBookmarks } from "react-icons/md";
import { RiBarChart2Line } from "react-icons/ri";
import { TbPlus, TbTarget } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import { CreatedArtifactFragmentFragment } from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import ExplorerBookmarkPopover from "@apps/explorer/components/bookmark-popover";
import FavouriteArtifactsPopover from "@apps/favourite-artifacts/favourite-artifacts-popover";
import useLabel from "@apps/use-label/use-label";
import {
  currentOrganizationVar,
  currentUserVar,
  isAdminVar,
} from "@cache/cache";
import Link, { useLink } from "@components/link/link";
import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";
import { getUrl, toWithBackground } from "@helpers/helpers";

type Props = {
  menuIsCollapsed: boolean;
  onClickSearch: () => void;
};

type SidebarNavItem = {
  id: string;
  name: string;
  href: string;
  groupName: string;
  hideGroupName?: boolean;
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
};

const getButtonClassName = (isActive = false, menuIsCollapsed: boolean) => {
  return classNames(
    "group flex-1 flex items-center text-base font-medium rounded-md gap-2.5 text-left px-2 w-full",
    menuIsCollapsed ? "justify-center py-1.5" : "justify-start py-0.5",
    isActive ? "text-blue-800 bg-blue-100" : "text-gray-700 hover:bg-black/5"
  );
};

const SidebarNavigationItem = ({
  item,
  menuIsCollapsed = false,
  onClickNavigation,
  isActive,
}: {
  item: SidebarNavItem;
  menuIsCollapsed: boolean;
  onClickNavigation: (
    item: SidebarNavItem
  ) => (e: MouseEvent<HTMLAnchorElement>) => void;
  isActive: boolean;
}) => (
  <div key={item.name}>
    <div
      className={classNames(
        "flex my-0.5 fs-unmask",
        menuIsCollapsed ? "my-1.5" : "my-0.5"
      )}
    >
      <Link
        to={item.href}
        className={getButtonClassName(isActive, menuIsCollapsed)}
        onClick={onClickNavigation(item)}
      >
        <Tooltip text={menuIsCollapsed ? item.name : ""}>
          <span>
            <item.icon
              className={classNames(
                `shrink-0`,
                menuIsCollapsed ? "h-5 w-5" : "h-4 w-4",
                isActive
                  ? "text-blue-800 group-hover:text-blue-800"
                  : "text-gray-500 group-hover:text-gray-600"
              )}
              aria-hidden="true"
            />
          </span>
        </Tooltip>
        {!menuIsCollapsed && item.name}
        {!menuIsCollapsed && item.id === "explorer" && (
          <div className="flex items-center gap-2">
            <ExplorerBookmarkPopover className="flex items-center">
              {({ setReferenceElement }) => (
                <Popover.Button
                  ref={setReferenceElement}
                  className="text-gray-500 hover:text-gray-800 p-0.5 focus:outline-none focus:ring-0 rounded bg-black/5 hover:bg-black/10"
                >
                  <MdOutlineBookmarks className="h-4 w-4" />
                </Popover.Button>
              )}
            </ExplorerBookmarkPopover>
            <FavouriteArtifactsPopover />
          </div>
        )}
      </Link>
    </div>
  </div>
);

const SidebarNavigation: React.FC<Props> = ({
  menuIsCollapsed,
  onClickSearch,
}: {
  menuIsCollapsed: boolean;
  onClickSearch: () => void;
}) => {
  const label = useLabel();
  const location = useLocation<TFLocationState>();
  const isAdmin = isAdminVar();
  const currentUser = currentUserVar();
  const currentOrganization = currentOrganizationVar();
  const hasReports = currentUser.directReports.edges.length > 0;
  const [showCreationDialog, setShowCreationDialog] = useState(false);
  const navigationItems: SidebarNavItem[] = compact([
    {
      id: "dashboard",
      name: "Home",
      href: "/dashboard",
      icon: HomeIcon,
      groupName: "Home",
    },
    currentOrganization.featureFlags.explorer && {
      id: "explorer",
      name: "Explorer",
      href: "/explorer",
      icon: SearchIcon,
      groupName: "Home",
    },
    currentOrganization.featureFlags.goals && {
      id: "goalOverview",
      name: label("goal", { pluralize: true, capitalize: true }),
      href: "/goals",
      icon: TbTarget,
      groupName: label("expectation", { pluralize: true }),
    },
    currentOrganization.featureFlags.kpis && {
      id: "kpis",
      name: "KPIs",
      href: "/kpis",
      icon: TrendingUpIcon,
      groupName: label("expectation", { pluralize: true }),
    },
    {
      id: "calendar",
      name: "Meetings",
      href: "/calendar",
      icon: CalendarIcon,
      groupName: label("conversation", { pluralize: true }),
    },
    {
      id: "templates",
      name: "Templates",
      href: "/templates",
      icon: DocumentDuplicateIcon,
      groupName: label("conversation", { pluralize: true }),
    },
    currentOrganization.featureFlags.recognitions && {
      id: "recognitions",
      name: label("recognition", { pluralize: true, capitalize: true }),
      href: "/recognition-board",
      icon: StarIcon,
      groupName: label("conversation", { pluralize: true }),
    },
    currentOrganization.featureFlags.feedbacks && {
      id: "feedback",
      name: label("feedback", { capitalize: true }),
      href: "/feedbacks/received",
      icon: AnnotationIcon,
      groupName: label("conversation", { pluralize: true }),
    },
    {
      id: "assessments",
      name: "Assessments",
      href: "/assessments",
      icon: MdOutlineAssessment,
      groupName: label("development"),
    },
    {
      id: "programs",
      name: "Programs",
      href: "/programs",
      icon: ViewGridIcon,
      alwaysShowing: false,
      groupName: label("development"),
    },
    (isAdmin || hasReports) && {
      id: "reporting",
      name: "Reporting",
      href: "/reporting",
      icon: RiBarChart2Line,
      groupName: label("development"),
    },
  ]);
  const navigationItemsByGroup = navigationItems.reduce((memo, navItem) => {
    if (!memo[navItem.groupName]) {
      memo[navItem.groupName] = [];
    }
    memo[navItem.groupName] = [...memo[navItem.groupName], navItem];
    return memo;
  }, {} as { [key: string]: SidebarNavItem[] });

  const handleClickNavigationItem =
    (item: SidebarNavItem): MouseEventHandler<HTMLAnchorElement> =>
    (e) => {
      if (item.id === "search") {
        e.preventDefault();
        onClickSearch();
      }
    };

  const link = useLink();
  const handleCloseCreationDialog = (
    artifact?: CreatedArtifactFragmentFragment
  ) => {
    setShowCreationDialog(false);
    if (artifact) {
      link.redirect(
        toWithBackground({
          pathname: getUrl({
            artifactId: artifact.id,
            artifactType: artifact.artifactType,
          }),
          location,
        })
      );
    }
  };

  const searchPlusButtonClassName =
    "text-xs bg-white shadow-sm tracking-tight px-2 py-1 rounded border text-gray-400 flex items-center gap-1 hover:text-gray-600 hover:border-gray-300 hover:bg-black/5";

  return (
    <>
      {showCreationDialog && (
        <ArtifactCreationDialog onClose={handleCloseCreationDialog} />
      )}
      <div
        className={classNames(
          "flex items-center gap-1.5 flex-col fs-unmask",
          !menuIsCollapsed && "hidden"
        )}
      >
        <Tooltip text="Search">
          <button
            className={classNames(
              menuIsCollapsed
                ? getButtonClassName(false, menuIsCollapsed)
                : searchPlusButtonClassName,
              "w-full"
            )}
            onClick={onClickSearch}
          >
            <SearchIcon className="h-5 w-5 text-gray-500" />
          </button>
        </Tooltip>
        <Tooltip text="Create">
          <div className="w-full">
            <button
              onClick={() => setShowCreationDialog(true)}
              className={classNames(
                menuIsCollapsed
                  ? getButtonClassName(false, menuIsCollapsed)
                  : searchPlusButtonClassName,
                "text-gray-500"
              )}
            >
              <TbPlus className="h-5 w-5 text-gray-500" />
              {!menuIsCollapsed && <span>New</span>}
            </button>
          </div>
        </Tooltip>
      </div>
      <div className={classNames("flex flex-col", !menuIsCollapsed && "gap-4")}>
        {Object.keys(navigationItemsByGroup).map((key, i) => (
          <div key={key}>
            {!menuIsCollapsed && i > 0 && (
              <div className="text-gray-400 text-2xs uppercase px-2 font-medium mb-1">
                {key}
              </div>
            )}
            {navigationItemsByGroup[key].map((item) => (
              <SidebarNavigationItem
                item={item}
                isActive={window.location.href.includes(item.href)}
                menuIsCollapsed={menuIsCollapsed}
                onClickNavigation={handleClickNavigationItem}
                key={item.name}
              />
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default SidebarNavigation;
