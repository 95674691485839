import { useMutation } from "@apollo/client";
import { FormEvent, useEffect, useState } from "react";

import { currentUserVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Input from "@components/input/input";
import useDebounce from "@components/use-debounce/use-debounce";
import { useNotificationError } from "@components/use-error/use-error";

import updateUserMutation from "../../graphql/update-user-mutation";

const Name = () => {
  const currentUser = currentUserVar();
  const [firstName, setFirstName] = useState(currentUser.firstName);
  const [lastName, setLastName] = useState(currentUser.lastName);
  const [updateUser] = useMutation(updateUserMutation);
  const debouncedFirstName = useDebounce(firstName, 500);
  const debouncedLastName = useDebounce(lastName, 500);
  const { onError } = useNotificationError();

  const handleSubmitUserForm = (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (!firstName.trim() || !lastName.trim()) {
      return;
    }
    updateUser({
      variables: {
        userId: currentUser.id,
        firstName,
        lastName,
      },
      onError,
    });
  };

  useEffect(() => {
    if (
      firstName !== currentUser.firstName ||
      lastName !== currentUser.lastName
    ) {
      handleSubmitUserForm();
    }
  }, [
    currentUser.firstName,
    currentUser.lastName,
    debouncedFirstName,
    debouncedLastName,
    firstName,
    lastName,
  ]);

  return (
    <form onSubmit={handleSubmitUserForm}>
      <div className="flex items-center gap-6">
        <Avatar user={currentUser} size="16" />
        <div>
          <Input
            required
            label="First name"
            aria-label="Account first name input"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div>
          <Input
            required
            label="Last name"
            aria-label="Account last name input"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
      </div>
    </form>
  );
};

export default Name;
