import { useMutation, useQuery } from "@apollo/client";
import { InformationCircleIcon, XIcon } from "@heroicons/react/outline";
import { validate } from "email-validator";
import { compact, flattenDeep, uniq } from "lodash";
import {
  ChangeEventHandler,
  FormEventHandler,
  MouseEventHandler,
  useState,
} from "react";
import {
  CreateOrUpdateFlowMutationMutation,
  CreateOrUpdateFlowMutationMutationVariables,
  GetFlowQueryQuery,
  GetFlowQueryQueryVariables,
} from "types/graphql-schema";

import getSidebarDataQuery from "@apps/main/graphql/get-sidebar-data-query";
import getFlowMeetingQuery from "@apps/meeting/components/flow/graphql/get-flow-meeting-query";
import getMeetingHistoryQuery from "@apps/meeting/graphql/get-meeting-history-query";
import { currentOrganizationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Input, { InputLabel } from "@components/input/input";
import { useLink } from "@components/link/link";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import CustomTextareaAutosize from "@components/textarea-autosize/textarea-autosize";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import {
  classNames,
  inputBorderClassName,
  inputFocusClassName,
} from "@helpers/css";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import FlowMeetingCombobox from "./components/flow-meeting-combobox";
import createOrUpdateFlowMutation from "./graphql/create-or-update-flow-mutation";
import deleteFlowMutation from "./graphql/delete-flow-mutation";
import getFlowQuery from "./graphql/get-flow-query";

const FlowEditDialog = ({
  flowId,
  onClose,
}: {
  flowId?: number;
  onClose: (flow?: any) => void;
}) => {
  const link = useLink();
  const currentOrganization = currentOrganizationVar();
  const [form, setForm] = useState<{
    flowId?: number;
    title: string;
    public: boolean;
    emails: string;
    meetingGroups: { id: number; title: string }[];
    organizationId: number;
  }>({
    flowId,
    title: "",
    public: false,
    emails: "",
    meetingGroups: [],
    organizationId: currentOrganization.id,
  });
  const formMeetingGroupIds = form.meetingGroups.map(({ id }) => id);
  const { data, loading: fetchLoading } = useQuery<
    GetFlowQueryQuery,
    GetFlowQueryQueryVariables
  >(getFlowQuery, {
    variables: flowId ? { flowId } : undefined,
    onCompleted: (response) => {
      const flow = assertNonNull(response.flow);
      setForm({
        ...form,
        flowId: flow.id,
        title: flow.title,
        public: flow.public,
        emails: flattenDeep(JSON.parse(flow.participantEmailPatterns)).join(
          ", "
        ),
        organizationId: assertNonNull(flow.organization?.id),
        meetingGroups: assertEdgesNonNull(flow.meetingGroups),
      });
    },
    skip: !flowId,
    onError: onNotificationErrorHandler(),
  });
  const [saveFlow, { loading: saveLoading }] = useMutation<
    CreateOrUpdateFlowMutationMutation,
    CreateOrUpdateFlowMutationMutationVariables
  >(createOrUpdateFlowMutation);
  const [deleteFlow, { loading: deleteLoading }] =
    useMutation(deleteFlowMutation);

  const handleChangeTitle: ChangeEventHandler<HTMLInputElement> = (e) => {
    setForm({ ...form, title: e.target.value });
  };

  const handleChangePublic: ChangeEventHandler<HTMLInputElement> = (e) => {
    setForm({ ...form, public: e.target.checked });
  };

  const handleChangeEmails: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setForm({ ...form, emails: e.target.value });
  };

  const handleSubmitForm: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const participantEmailPatterns = JSON.stringify([
      uniq(
        flattenDeep(
          compact(
            form.emails
              .split(/[ ,]+/)
              .map((email) => email.trim())
              .filter(validate)
          )
        ) || []
      ),
    ]);
    saveFlow({
      refetchQueries: [
        getFlowMeetingQuery,
        getMeetingHistoryQuery,
        getSidebarDataQuery,
      ],
      variables: {
        ...form,
        participantEmailPatterns,
        meetingGroupIds: formMeetingGroupIds,
      },
      onError: onNotificationErrorHandler(),
      onCompleted: (response) => {
        onClose(response.createOrUpdateFlow?.flow);
      },
    });
  };

  const handleClickDeleteFlow: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this meeting group?")) {
      deleteFlow({
        onError: onNotificationErrorHandler(),
        variables: { flowId },
        refetchQueries: [getSidebarDataQuery],
        onCompleted: () => {
          link.redirect("/");
        },
      });
    }
  };

  const handleChangeMeetings = (option: any) => {
    setForm({
      ...form,
      meetingGroups: form.meetingGroups.concat(option.meetingGroup),
    });
  };
  const handleRemoveMeetingGroup = (id: number) => () => {
    setForm({
      ...form,
      meetingGroups: form.meetingGroups.filter(
        (meetingGroup) => meetingGroup.id !== id
      ),
    });
  };

  const flow = data?.flow;

  return (
    <Modal onClose={onClose}>
      <div className="p-6 flex flex-col gap-6" aria-label="Edit flow dialog">
        <div className="w-full">
          <ModalTitle onClose={onClose}>
            <div className="flex gap-2">
              {flowId ? "Edit" : "Create"} meeting group
              <a
                href="https://intercom.help/topicflow/en/articles/7852403-flows"
                target="_blank"
                rel="noreferrer"
                className="text-gray-400 flex gap-1 items-center text-xs hover:underline font-normal"
              >
                <InformationCircleIcon className="w-4 h-4" /> Learn more about
                Meeting groups
              </a>
            </div>
          </ModalTitle>
        </div>
        <form className="flex flex-col gap-6" onSubmit={handleSubmitForm}>
          <Input
            label="Meeting group title"
            value={form.title}
            className="w-96"
            aria-label="Flow title input"
            onChange={handleChangeTitle}
          />

          <div className="max-w-96">
            <InputLabel label="With selected meetings" />
            <div>
              <div className="w-full mt-1">
                <FlowMeetingCombobox
                  label="Meetings"
                  onChange={handleChangeMeetings}
                  excludeMeetingGroupIds={formMeetingGroupIds}
                />
              </div>
            </div>
            <div className="mt-2 flex flex-col border rounded divide-y empty:hidden w-96">
              {form.meetingGroups.map((meetingGroup) => (
                <div
                  key={meetingGroup.id}
                  className="flex justify-between gap-2 px-4 py-2 text-sm"
                >
                  {meetingGroup.title}
                  <button
                    className=""
                    onClick={handleRemoveMeetingGroup(meetingGroup.id)}
                  >
                    <XIcon className="w-4 h-4" />
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className="max-w-96">
            <InputLabel label="Or any meetings with participant emails" />
            <div>
              <CustomTextareaAutosize
                placeholder="name@domain.com, ..."
                value={form.emails}
                onChange={handleChangeEmails}
                className={classNames(
                  "mt-1 px-4 py-2 block w-full sm:text-sm",
                  inputBorderClassName,
                  inputFocusClassName
                )}
              />
              <div className="mt-1 text-2xs text-gray-500">
                Separate emails by comma.
              </div>
            </div>
          </div>

          <div>
            <label className="flex gap-1 text-sm mt-1">
              <input
                type="checkbox"
                checked={form.public}
                onChange={handleChangePublic}
              />
              <span className="font-medium">Public:</span> this meeting group
              will be visible to participants of grouped meetings.
            </label>
          </div>

          <div className="flex gap-4 justify-between items-center">
            <div className="flex gap-4">
              <Button
                disabled={saveLoading || fetchLoading || deleteLoading}
                text="Save"
                theme={buttonTheme.primary}
                type="submit"
              />
              <Button disabled={saveLoading} text="Cancel" onClick={onClose} />
            </div>
            <div>
              {flow?.canDelete?.permission && (
                <Button
                  theme={buttonTheme.redDanger}
                  disabled={saveLoading || deleteLoading}
                  text="Delete"
                  onClick={handleClickDeleteFlow}
                />
              )}
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default FlowEditDialog;
