import { useQuery } from "@apollo/client";
import { compact, range } from "lodash";
import { Fragment, ReactElement, useCallback } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { MdOutlineDragIndicator } from "react-icons/md";
import {
  ActionItemCollapsibleFragmentFragment,
  ActionItemState,
  GetActionItemsCollapsibleQueryQuery,
  GetActionItemsCollapsibleQueryQueryVariables,
} from "types/graphql-schema";

import Artifact from "@apps/artifact/artifact";
import useUiPreferenceCache, {
  UiPreferenceCache,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import { currentOrganizationVar } from "@cache/cache";
import CollapsibleContainer from "@components/collapsible-container/collapsible-container";
import Loading from "@components/loading/loading";
import { useNotificationError } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import getActionItemsCollapsibleQuery from "./graphql/get-action-items-collapsible-query";

export const dashboardActionItemsFirstPageLimit = 5;

const ActionItemsCollapsibleContainer = ({
  actionItemState,
  uiPreferenceCacheNamespace,
  path = "",
  roundedTop = false,
  roundedBottom = false,
  queryVariables,
  filterResults,
  titleRightSide = null,
}: {
  actionItemState: Pick<ActionItemState, "label" | "value">;
  uiPreferenceCacheNamespace: keyof UiPreferenceCache;
  path?: string;
  roundedTop?: boolean;
  roundedBottom?: boolean;
  queryVariables: GetActionItemsCollapsibleQueryQueryVariables;
  filterResults?: (
    variables: GetActionItemsCollapsibleQueryQueryVariables
  ) => (node: ActionItemCollapsibleFragmentFragment) => boolean;
  titleRightSide?: ReactElement | null;
}) => {
  const organization = currentOrganizationVar();

  // HOOKS
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const open =
    uiPreferenceCache[`${uiPreferenceCacheNamespace}`] === null ||
    uiPreferenceCache[`${uiPreferenceCacheNamespace}`].includes(
      actionItemState.value
    );

  const limit = 20;
  const { onError } = useNotificationError();
  const { loading, data, fetchMore } = useQuery<
    GetActionItemsCollapsibleQueryQuery,
    GetActionItemsCollapsibleQueryQueryVariables
  >(getActionItemsCollapsibleQuery, {
    variables: queryVariables,
    skip: !open,
    onError,
  });

  const artifacts = data?.artifacts
    ? assertEdgesNonNull(data.artifacts).filter(
        (d) => d.__typename === "ActionItemArtifactNode"
      )
    : [];
  const actionItems = artifacts.filter(
    (actionItem) =>
      actionItem.__typename === "ActionItemArtifactNode" &&
      actionItem.actionItemState === actionItemState.value
  );
  const filteredActionItems = filterResults
    ? actionItems.filter((actionItem) =>
        filterResults(queryVariables)(actionItem)
      )
    : actionItems;

  const handleClickMore = () => {
    fetchMore({
      variables: {
        merge: true,
        after: data?.artifacts?.pageInfo.endCursor,
        first: limit,
      },
    });
  };

  const handleToggleCollapsible = useCallback(
    (isOpen: boolean) => {
      const allActionItemStates = compact(organization.actionItemStates).map(
        ({ value }) => value
      );
      const states =
        uiPreferenceCache[`${uiPreferenceCacheNamespace}`] ||
        allActionItemStates;
      const newVal = isOpen
        ? states.concat(actionItemState.value)
        : states.filter((state: number) => actionItemState.value !== state);
      saveUiPreference({ [`${uiPreferenceCacheNamespace}`]: newVal });
    },
    [
      actionItemState.value,
      organization.actionItemStates,
      saveUiPreference,
      uiPreferenceCache,
      uiPreferenceCacheNamespace,
    ]
  );

  // RENDER;
  return (
    <>
      <Droppable
        droppableId={String(actionItemState.value)}
        type="action-item-state"
      >
        {(providedDroppable, snapshotDroppable) => (
          <div
            ref={providedDroppable.innerRef}
            className={classNames("relative")}
            {...providedDroppable.droppableProps}
            aria-label={`${actionItemState.label.toLowerCase()} action items droppable container`}
          >
            <CollapsibleContainer
              roundedTop={roundedTop}
              roundedBottom={roundedBottom}
              container={Fragment}
              startOpen={open || snapshotDroppable.isDraggingOver}
              title={actionItemState.label}
              onToggle={handleToggleCollapsible}
              titleRightSide={titleRightSide}
            >
              <div className="relative">
                {!data && loading ? (
                  <div className="flex flex-col divide-y divide-gray-100">
                    {range(2).map((i) => (
                      <div
                        key={i}
                        className="flex justify-between gap-8 bg-white px-4 py-3"
                      >
                        <div className="skeleton h-5 w-72 rounded-lg" />
                        <div className="skeleton h-5 w-16 rounded-lg" />
                      </div>
                    ))}
                    {providedDroppable.placeholder}
                  </div>
                ) : snapshotDroppable.isDraggingOver ||
                  filteredActionItems.length > 0 ? (
                  <div
                    className="divide-y divide-gray-100"
                    aria-label="Action items collapsible container"
                  >
                    {filteredActionItems.map((actionItem, index) => (
                      <Draggable
                        key={actionItem.id}
                        draggableId={String(actionItem.id)}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <div
                              className={classNames(
                                "px-3 py-2 relative group",
                                snapshot.isDragging &&
                                  "bg-purple-50/90 shadow-xl rounded-xl"
                              )}
                            >
                              <button
                                {...provided.dragHandleProps}
                                className={classNames(
                                  "absolute top-1 -left-3",
                                  "text-gray-500 hover:text-gray-800 bg-white hover:bg-gray-50",
                                  "hidden group-hover:block",
                                  "px-0.5 py-1 cursor-move rounded border"
                                )}
                                aria-label="Artifact drag button"
                              >
                                <MdOutlineDragIndicator className="h-4 w-4" />
                              </button>
                              <Artifact
                                artifact={actionItem}
                                urlPrefix={path}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {providedDroppable.placeholder}
                    {data?.artifacts?.pageInfo.hasNextPage && (
                      <div className="px-4 py-2 flex items-center justify-center">
                        <button
                          className="text-gray-500 text-xs mr-4 hover:bg-gray-100 rounded px-1.5 py-0.5"
                          onClick={handleClickMore}
                          disabled={loading}
                        >
                          View more
                        </button>
                        {loading && <Loading size="5" mini />}
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <div className="px-4 py-2 text-gray-500 text-sm">
                      No action items.
                    </div>
                    {providedDroppable.placeholder}
                  </div>
                )}
              </div>
            </CollapsibleContainer>
          </div>
        )}
      </Droppable>
    </>
  );
};

export default ActionItemsCollapsibleContainer;
