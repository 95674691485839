import { gql } from "@apollo/client";

export default gql`
  mutation deleteFlowMutation($flowId: Int!) {
    deleteFlow(flowId: $flowId) {
      flow {
        id
      }
    }
  }
`;
