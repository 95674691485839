import { useQuery } from "@apollo/client";
import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router-dom";

import Container from "@components/container/container";
import GraphqlError from "@components/error/graphql-error";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";

import MeetingDraftContainer from "../draft-container";
import Meeting from "../meeting";
import MeetingHistory from "../meeting-history";
import MeetingViewSidebar from "../meeting-sidebar/meeting-view-sidebar";
import getFlowMeetingQuery from "./graphql/get-flow-meeting-query";

interface Props {
  flow: any;
}

const FlowTab: React.FC<Props> = ({ flow }) => {
  const params = useParams<{ meetingId: string }>();
  const meetingId = parseInt(params.meetingId);
  const [now] = useState(moment().format());

  const { loading, error, data } = useQuery(getFlowMeetingQuery, {
    variables: {
      meetingId,
      flowId: flow.id,
      now,
      hasMeetingId: !!meetingId,
    },
    onError: onNotificationErrorHandler(),
  });

  const meeting =
    data?.meeting ||
    data?.flow?.nextMeetings?.edges[0]?.node ||
    data?.flow?.lastMeetings?.edges[0]?.node;

  // Renders
  if (error) {
    return (
      <Container>
        <GraphqlError
          error={error}
          whatDidNotWork="The meeting could not be loaded."
        />
      </Container>
    );
  }
  if (!data && loading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }
  if (!data) {
    return null;
  }

  return (
    <div className="flex-1 flex min-w-0">
      <main
        className={classNames(
          "flex-1 min-w-0",
          "p-0 pt-3 sm:pt-6 sm:p-6",
          "lg:pr-96 xl:pr-120 2xl:pr-144" // padding and space for sidebar
        )}
      >
        {meeting ? (
          <div className="mb-6">
            <MeetingDraftContainer meeting={meeting}>
              {() => (
                <Meeting
                  meeting={meeting}
                  opensTopicsInSidebar={false}
                  currentPageMeeting={meeting}
                />
              )}
            </MeetingDraftContainer>
          </div>
        ) : (
          <div className="text-gray-600">No meetings</div>
        )}

        {meeting && <MeetingHistory currentPageMeeting={meeting} />}
      </main>

      {meeting && (
        <aside
          className={classNames(
            "hidden lg:block",
            "lg:w-96 xl:w-120 2xl:w-144",
            "fixed right-0 bottom-0 top-14 overflow-y-scroll"
          )}
          aria-label="Meeting sidebar"
        >
          <div
            className={classNames(
              "flex flex-col gap-8 pt-6 px-6",
              "pb-24" // extra padding for intercom
            )}
          >
            <MeetingViewSidebar meeting={meeting} />
          </div>
        </aside>
      )}
    </div>
  );
};

export default FlowTab;
