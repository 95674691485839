import { compact } from "lodash";
import { ArtifactActivityFragmentFragment } from "types/graphql-schema";

import ActivityChangedField from "./changed-field";

const ActivityArtifactUpdate = ({
  activity,
}: {
  activity: ArtifactActivityFragmentFragment;
}) => {
  if (!activity?.changedFields || activity.changedFields.length === 0) {
    return null;
  }

  const changedFields = activity.changedFields.map((changedField) => {
    return (
      <ActivityChangedField
        key={changedField?.fieldName}
        changedField={changedField}
      />
    );
  });
  return <div className="flex flex-col gap-1">{compact(changedFields)}</div>;
};

export default ActivityArtifactUpdate;
