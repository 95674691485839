import { AriaAttributes, ReactElement } from "react";

import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import { classNames } from "@helpers/css";

const CollapsibleContainerParent = ({
  children,
  title,
  titleLink,
  loading = false,
  rightSide = null,
  className = "",
  ariaProps = {},
}: {
  ariaProps?: AriaAttributes;
  title: string;
  titleLink?: string;
  loading?: boolean;
  className?: string;
  children: ReactElement | ReactElement[];
  rightSide?: ReactElement | ReactElement[] | null;
}) => (
  <div
    className={classNames(
      "rounded-lg shadow divide divide-y divide-gray-100 items-center",
      className
    )}
    aria-label={ariaProps["aria-label"]}
  >
    <div className="bg-white pl-4 pr-3 py-2 rounded-t-lg flex justify-between items-center gap-x-2 gap-y-0.5 flex-wrap">
      <div className="shrink-0 text-lg font-medium flex items-center gap-4">
        {titleLink ? (
          <AppLink className="hover:underline" to={titleLink}>
            {title}
          </AppLink>
        ) : (
          <div>{title}</div>
        )}
        {loading && <Loading mini size="5" />}
      </div>
      {rightSide}
    </div>
    {children}
  </div>
);

export default CollapsibleContainerParent;
