import { useQuery } from "@apollo/client";
import { useState } from "react";
import { Cell, Label, Pie, PieChart } from "recharts";
import {
  AdoptionReportMetric,
  GetAdoptionReportQuery,
  GetAdoptionReportQueryVariables,
  ReportFilterInput,
} from "types/graphql-schema";

import getAdoptionReportQuery from "@apps/reporting/graphql/get-adoption-report-query";
import { metricLabels } from "@apps/reporting/helpers";
import { currentOrganizationVar } from "@cache/cache";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { CHART_COMPLETE_COLOR, CHART_INCOMPLETE_COLOR } from "@helpers/charts";
import { classNames } from "@helpers/css";

const CompletionChart = ({
  metric,
  labelOnRight,
  filters,
}: {
  metric: AdoptionReportMetric;
  labelOnRight?: boolean;
  filters: ReportFilterInput[];
}) => {
  const organization = currentOrganizationVar();
  const [report, setReport] = useState<
    GetAdoptionReportQuery["adoptionReport"] | null
  >(null);
  const { loading: isLoadingReport } = useQuery<
    GetAdoptionReportQuery,
    GetAdoptionReportQueryVariables
  >(getAdoptionReportQuery, {
    variables: {
      organizationId: organization.id,
      attributes: [],
      filters,
      metrics: [metric],
    },
    onCompleted: (response) => {
      setReport(response.adoptionReport);
    },
    onError: onNotificationErrorHandler(),
  });

  const completionCount = report
    ? report.data.filter((row) => (row?.metricValues[0] ?? 0) > 0).length
    : 0;
  const completionData = [
    {
      name: "Complete",
      value: completionCount,
      color: CHART_COMPLETE_COLOR,
    },
    {
      name: "Incomplete",
      value: report ? report.data.length - completionCount : 0,
      color: CHART_INCOMPLETE_COLOR,
    },
  ];

  if (isLoadingReport) {
    const labelLoader = (
      <div
        className={classNames(
          "flex flex-1 flex-col gap-2",
          !labelOnRight && "items-end"
        )}
      >
        <div className="rounded-md bg-gray-300 h-10 w-1/2"></div>
        <div className="rounded-md bg-gray-300 h-8 w-full"></div>
      </div>
    );
    return (
      <div className="animate-pulse w-full">
        <div className="flex flex-1 items-center gap-2">
          {!labelOnRight && labelLoader}
          <div className="m-10 rounded-full bg-gray-300 h-[170px] w-[170px]"></div>
          {labelOnRight && labelLoader}
        </div>
      </div>
    );
  }

  const label = (
    <div className={classNames("flex flex-col", !labelOnRight && "items-end")}>
      <div className="text-2xl font-bold">{report?.totalValues[0]}</div>
      <div className="font-bold text-right">
        {metricLabels[metric].toLowerCase()}
      </div>
    </div>
  );

  return (
    <div className="flex items-center gap-2">
      {!labelOnRight && label}

      <PieChart width={240} height={240}>
        <Pie
          data={completionData}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={90}
          dataKey="value"
        >
          <>
            <Label
              value={
                Math.round(
                  (completionData[0].value /
                    (completionData[0].value + completionData[1].value)) *
                    100
                ) + "% of users"
              }
              position="center"
            />
            {completionData.map((entry) => (
              <Cell key={`cell-${entry.name}`} fill={entry.color} />
            ))}
          </>
        </Pie>
      </PieChart>

      {labelOnRight && label}
    </div>
  );
};

export default CompletionChart;
