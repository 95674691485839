import { useMutation, useQuery } from "@apollo/client";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { useCallback, useMemo } from "react";
import { MdContentCopy } from "react-icons/md";
import {
  GetAssessmentGroupsQuery,
  GetAssessmentGroupsQueryVariables,
  RemoveAssessmentGroupMutation,
  RemoveAssessmentGroupMutationVariables,
} from "types/graphql-schema";

import { currentOrganizationVar, successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Heading from "@components/heading/heading";
import Loading from "@components/loading/loading";
import ManagedTable from "@components/table/managed-table";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assessmentTypeLabels } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";

import deleteAssessmentGroupMutation from "../graphql/delete-assessment-group-mutation";
import getAssessmentGroupsQuery from "../graphql/get-assessment-groups-query";
import { bgClassName } from "../helpers";

const AssessmentGroups = () => {
  const organization = currentOrganizationVar();
  const { ConfirmationDialog, confirm } = useConfirm(
    "Are you sure?",
    "Are you sure you want to delete this assessment template?"
  );
  const { data, loading } = useQuery<
    GetAssessmentGroupsQuery,
    GetAssessmentGroupsQueryVariables
  >(getAssessmentGroupsQuery, {
    variables: { organizationId: organization.id },
    onError: onNotificationErrorHandler(),
  });

  const [removeAssessmentGroup, { loading: isDeletingGroup }] = useMutation<
    RemoveAssessmentGroupMutation,
    RemoveAssessmentGroupMutationVariables
  >(deleteAssessmentGroupMutation);

  const assessmentGroups = useMemo(() => {
    return data?.assessmentGroups
      ? assertEdgesNonNull(data.assessmentGroups)
      : [];
  }, [data]);

  const handleDeleteAssessmentGroup = useCallback(
    async (assessmentGroupId: number) => {
      const confirmation = await confirm();
      if (confirmation) {
        removeAssessmentGroup({
          variables: { assessmentGroupId },
          refetchQueries: [getAssessmentGroupsQuery],
          onCompleted: () =>
            successNotificationVar({ title: "Assessment template deleted" }),
          onError: onNotificationErrorHandler(),
        });
      }
    },
    [confirm, removeAssessmentGroup]
  );

  if (loading) {
    return (
      <Loading className={classNames(bgClassName, "p-6 w-full mx-auto")}>
        Loading
      </Loading>
    );
  }

  return (
    <div
      className={classNames(bgClassName, "p-6 w-full")}
      aria-label="Assessments > groups"
    >
      <ConfirmationDialog />
      <div className="flex w-full justify-between items-center">
        <Heading small title="Assessment Templates" />
        <div className="flex items-center gap-2">
          {isDeletingGroup && <Loading mini size="5" />}
          <Button
            disabled={isDeletingGroup}
            theme={buttonTheme.default}
            to="/assessments/assessment-templates/new"
            text="New assessment template"
          />
        </div>
      </div>

      <div className="mt-4">
        {assessmentGroups.length ? (
          <ManagedTable
            sortingEnabled
            searchEnabled
            sortKey="title"
            sortDir="asc"
            data={assessmentGroups}
            columns={[
              {
                header: "Title",
                field: "title",
                sortKey: "title",
              },
              {
                header: "Assessment type",
                render: (row) => assessmentTypeLabels[row.assessmentType],
                sortKey: "type",
                value: (row) => assessmentTypeLabels[row.assessmentType],
              },
              {
                header: "",
                render: (row) => (
                  <div className="flex items-center gap-2 justify-end">
                    <Button
                      disabled={isDeletingGroup}
                      theme={buttonTheme.lightBlue}
                      to={`/assessments/assessment-templates/${row.id}/edit`}
                    >
                      <PencilIcon className="h-4 w-4" />
                    </Button>
                    <Button
                      disabled={isDeletingGroup}
                      theme={buttonTheme.lightBlue}
                      to={`/assessments/assessment-templates/${row.id}/duplicate`}
                    >
                      <MdContentCopy className="h-4 w-4" />
                    </Button>
                    <Button
                      disabled={!row.canDelete?.permission || isDeletingGroup}
                      theme={buttonTheme.redDanger}
                      tooltip={
                        !row.canDelete?.permission
                          ? row.canDelete?.reason ?? undefined
                          : undefined
                      }
                      onClick={() => handleDeleteAssessmentGroup(row.id)}
                    >
                      <TrashIcon className="h-4 w-4" />
                    </Button>
                  </div>
                ),
              },
            ]}
          />
        ) : (
          <div className="text-gray-500">No assessment templates...</div>
        )}
      </div>
    </div>
  );
};

export default AssessmentGroups;
