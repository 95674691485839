import { useMutation } from "@apollo/client";
import { TrashIcon } from "@heroicons/react/outline";
import { MenuItem } from "@szhsin/react-menu";

import { errorNotificationVar } from "@cache/cache";
import removeTopicFromCache from "@cache/remove-topic";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { isEmptyValue } from "@components/wysiwyg/helpers";

import deleteTopicMutation from "../graphql/delete-topic-mutation";

const DeleteMenuItem = ({ topic, meeting, onDelete, ...props }) => {
  /* Mutations */
  const [deleteTopic] = useMutation(deleteTopicMutation, {
    onCompleted: () => {
      onDelete();
    },
    onError: () => {
      errorNotificationVar({
        description: "The topic could not be deleted.",
      });
    },
    update: () => {
      removeTopicFromCache({
        topic,
        meeting,
      });
    },
  });

  /* Handler */
  const handleDeleteTopic = (e) => {
    const editorEl = document.querySelector(
      `#editor-topic-${topic.id} .js-topic-discussion-notes-input`
    );
    const isEmpty = editorEl && isEmptyValue(editorEl.editor.getJSON());
    // eslint-disable-next-line no-restricted-globals
    if (isEmpty || confirm("Are you sure you want to delete this topic?")) {
      deleteTopic({
        variables: { topicId: topic.id },
        optimisticResponse: {
          deleteTopic: {
            ok: true,
          },
        },
        onError: onNotificationErrorHandler(),
      });
      e.stopPropagation = true;
    }
  };

  // Render
  const menuItemClassName = "text-sm ";
  return (
    <MenuItem
      className={menuItemClassName}
      onClick={handleDeleteTopic}
      data-testid="topic-dropdown-delete"
      {...props}
    >
      <TrashIcon
        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
        aria-hidden="true"
      />{" "}
      Delete
    </MenuItem>
  );
};

export default DeleteMenuItem;
