import { ErrorBoundary } from "@sentry/react";
import { GetTopicQuery, TopicDataFragmentFragment } from "types/graphql-schema";

import Error from "@components/error/error";
import WYSIWYG from "@components/wysiwyg/wysiwyg";
import { classNames } from "@helpers/css";
import { parseStringToJSON } from "@helpers/helpers";

const DiscussionNotes = ({
  topic,
  meeting,
  searchQuery = "",
}: {
  topic: TopicDataFragmentFragment;
  meeting: NonNullable<NonNullable<GetTopicQuery["topic"]>["meeting"]>;
  searchQuery?: string;
}) => {
  return (
    <div data-testid="topic-sidebar-discussion-notes">
      <ErrorBoundary
        fallback={
          <Error
            title="An unexpected error occurred."
            description={
              "The editor could not be rendered. We have been notified. Please refresh the page."
            }
          />
        }
      >
        <div className="relative">
          <WYSIWYG
            key={topic.id}
            value={parseStringToJSON(topic.discussionNotes)}
            className={classNames(
              "text-base ",
              // add padding so the + and drag button are within the editor. Needs to keep some space between buttons and editor so we handle correctly hiding the buttons when mouse leaves editor
              // https://github.com/Topicflow/topicflow/pull/1314
              "py-4 pl-[54px] pr-6",
              topic.canUpdate.permission && "min-h-28"
            )}
            enableComment={topic.canUpdate.permission}
            editable={topic.canUpdate.permission}
            showPlusButton={topic.canUpdate.permission}
            showFixedMenu={topic.canUpdate.permission}
            isInSidebar={true}
            uploadVariable={{ topicId: topic.id }}
            websocketConfig={{
              socketId: `meeting-${meeting.id}`,
              documentId: `topic-${topic.id}`,
              websocketToken: meeting.websocketToken,
            }}
            placeholder={
              topic.canUpdate.permission
                ? "Type '/' to format the topic notes or add action items or decisions"
                : "No notes."
            }
            mentionsConfig={{
              meetingGroupId: meeting.meetingGroup.id,
              meetingId: meeting.id,
            }}
            highlightSearchQuery={searchQuery}
            extraContext={{
              topicId: topic.id,
              relatedTopicId: topic.id,
              meetingId: meeting.id,
              meetingDate: meeting.startDatetime,
              meetingGroupId: meeting.meetingGroup.id,
              organizationId: meeting.organization?.id,
            }}
          />
        </div>
      </ErrorBoundary>
    </div>
  );
};

export default DiscussionNotes;
