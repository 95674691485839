import {
  AdoptionReportAttribute,
  AdoptionReportMetric,
  ComplianceProgramTargetFragment,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import { assertEdgesNonNull } from "@helpers/helpers";

import { TeamPickerTeam } from "./components/team-picker";

export const attributeLabels: { [key in AdoptionReportAttribute]: string } = {
  [AdoptionReportAttribute.Name]: "Name",
  [AdoptionReportAttribute.Email]: "Email",
  [AdoptionReportAttribute.LastSeen]: "Last activity",
  [AdoptionReportAttribute.ManagerEmail]: "Manager email",
  [AdoptionReportAttribute.ManagerName]: "Manager name",
};
export const metricLabels: { [key in AdoptionReportMetric]: string } = {
  [AdoptionReportMetric.OneononeMeetingsAsManager]: "1-on-1s as manager",
  [AdoptionReportMetric.OneononeMeetingsAsParticipant]:
    "1-on-1s as participant",
  [AdoptionReportMetric.GoalsCreated]: "Goals created",
  [AdoptionReportMetric.GoalUpdates]: "Goal updates",
  [AdoptionReportMetric.CareerGoalUpdates]: "Career goal updates",
  [AdoptionReportMetric.RecognitionsGiven]: "Recognitions given",
  [AdoptionReportMetric.RecognitionsReceived]: "Recognitions received",
  [AdoptionReportMetric.AssessmentsAsTarget]: "Assessments received",
  [AdoptionReportMetric.ActionItemsCreated]: "Action items created",
  [AdoptionReportMetric.DecisionsCreated]: "Decisions created",
  [AdoptionReportMetric.DocumentsCreated]: "Documents created",
};

export const getManagerEffectivenessAnswerPercentage = (
  question: { startValue: number; endValue: number },
  avgAnswer: number
) => {
  const percentagePerValue = 100 / (question.endValue - question.startValue);
  return (avgAnswer - question.startValue) * percentagePerValue;
};

export const matchesUserFilter = (
  userFilterList: BasicUser[],
  target: ComplianceProgramTargetFragment
) => {
  return (
    userFilterList.length === 0 ||
    !!userFilterList.find((u) => u.id === target.id)
  );
};

export const matchesReportsToFilter = (
  reportsToFilterList: BasicUser[],
  target: ComplianceProgramTargetFragment
) => {
  return (
    reportsToFilterList.length === 0 ||
    !!reportsToFilterList.find((u) => {
      const managers = target.managers
        ? assertEdgesNonNull(target.managers)
        : [];
      return managers.map((manager) => manager.id).includes(u.id);
    })
  );
};

export const matchesTeamsFilter = (
  teamsFilterList: TeamPickerTeam[],
  target: ComplianceProgramTargetFragment
) => {
  return (
    teamsFilterList.length === 0 ||
    !!teamsFilterList.find((t) => {
      const teams = target.teams ? assertEdgesNonNull(target.teams) : [];
      return teams.map((team) => team.id).includes(t.id);
    })
  );
};
