import { useQuery } from "@apollo/client";
import { useState } from "react";
import {
  AssessmentNominationFragment,
  GetAssessmentNominationsQuery,
  GetAssessmentNominationsQueryVariables,
} from "types/graphql-schema";

import getAssessmentNominationsQuery from "@apps/assessments/graphql/get-assessment-nominations-query";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import Loading from "@components/loading/loading";
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableFooter,
  TableHeadCell,
  TableHeadRow,
} from "@components/table/table";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNullWithStringId } from "@helpers/helpers";

import AssessmenNominationtDialog from "./nomination-dialog";

const AssessmenNominationtRow = ({
  assessmentNomination,
}: {
  assessmentNomination: AssessmentNominationFragment;
}) => {
  const [showNominationDialog, setShowNominationDialog] = useState(false);

  const handleCloseDialog = () => setShowNominationDialog(false);
  return (
    <TableBodyRow>
      <TableBodyCell>
        {assessmentNomination.complianceProgram.title}
      </TableBodyCell>
      <TableBodyCell>
        <div className="flex items-center gap-1">
          {assessmentNomination.assessmentGroup.title}
        </div>
      </TableBodyCell>
      <TableBodyCell>
        <div className="flex items-center gap-1">
          <Avatar user={assessmentNomination.targetUser} size={4} />
          {assessmentNomination.targetUser.name}
        </div>
      </TableBodyCell>
      <TableBodyCell>
        <div className="flex items-center gap-1">
          {assessmentNomination.nominations.totalCount}
        </div>
      </TableBodyCell>
      <TableBodyCell className="text-right">
        {showNominationDialog && (
          <AssessmenNominationtDialog
            assessmentNomination={assessmentNomination}
            onClose={handleCloseDialog}
          />
        )}
        <Button
          theme={buttonTheme.lightBlue}
          onClick={() => setShowNominationDialog(true)}
        >
          Nominate
        </Button>
      </TableBodyCell>
    </TableBodyRow>
  );
};

const MyAssessmentsNominations = ({
  userFilter,
}: {
  userFilter: number | null;
}) => {
  const { data, loading, fetchMore } = useQuery<
    GetAssessmentNominationsQuery,
    GetAssessmentNominationsQueryVariables
  >(getAssessmentNominationsQuery, {
    variables: {
      targetId: userFilter,
    },
    onError: onNotificationErrorHandler(),
  });

  const assessmentNominations = data?.assessmentsOpenForNominations
    ? assertEdgesNonNullWithStringId(data.assessmentsOpenForNominations)
    : [];

  const handleLoadMore = () => {
    if (data?.assessmentsOpenForNominations.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          merge: true,
          after: data.assessmentsOpenForNominations.pageInfo.endCursor,
        },
      });
    }
  };

  if (loading) {
    return <Loading className="p-6 w-full mx-auto">Loading</Loading>;
  }

  return (
    <>
      <TableContainer aria-label="Nominations assessments">
        <Table>
          <TableHeadRow>
            <TableHeadCell>Program</TableHeadCell>
            <TableHeadCell>Assessment</TableHeadCell>
            <TableHeadCell>Subject</TableHeadCell>
            <TableHeadCell>Nominations</TableHeadCell>
            <TableHeadCell className="w-52"></TableHeadCell>
          </TableHeadRow>
          <TableBody>
            {assessmentNominations.map((assessmentNomination) => (
              <AssessmenNominationtRow
                assessmentNomination={assessmentNomination}
                key={assessmentNomination.id}
              />
            ))}
            {!loading && assessmentNominations.length === 0 && (
              <TableBodyRow>
                <TableBodyCell colSpan={5}>
                  No assessment nominations.
                </TableBodyCell>
              </TableBodyRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TableFooter>
        {data?.assessmentsOpenForNominations.pageInfo.hasNextPage && (
          <div className="flex justify-center">
            {loading ? (
              <Loading mini size="5" />
            ) : (
              <button
                onClick={handleLoadMore}
                className="text-gray-500 hover:underline"
              >
                View more
              </button>
            )}
          </div>
        )}
      </TableFooter>
    </>
  );
};

export default MyAssessmentsNominations;
