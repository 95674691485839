import { ArrowSmRightIcon } from "@heroicons/react/outline";
import { capitalize, startCase } from "lodash";
import numeral from "numeral";

import useLabel from "@apps/use-label/use-label";

const ActivityChangedField = ({
  changedField,
}: {
  changedField?: any | null;
}) => {
  const label = useLabel();
  if (!changedField?.fieldName) return null;
  const { fieldName } = changedField;
  const numberFieldNames = ["start_value", "target_value", "current_value"];
  return (
    <div key={fieldName} className="flex items-start gap-1">
      <span className="font-medium">
        {capitalize(startCase(fieldName || "Field"))}:
      </span>
      <div key={fieldName} className="flex items-center gap-1 flex-wrap">
        {fieldName.includes("date") && changedField?.previousValue === null
          ? "No date"
          : !changedField?.previousValue
          ? "No value"
          : changedField.previousValueObject?.__typename === "UserNode"
          ? changedField.previousValueObject.name
          : numberFieldNames.includes(fieldName)
          ? numeral(changedField.previousValue).format("0[.][0000000000000]")
          : label(String(changedField.previousValue), { capitalize: true })}
        <ArrowSmRightIcon className="h-4 w-4 text-gray-500" />
        {fieldName.includes("date") && changedField.newValue === null
          ? "No date"
          : !changedField?.newValue
          ? "No value"
          : changedField.newValueObject?.__typename === "UserNode"
          ? changedField.newValueObject.name
          : numberFieldNames.includes(fieldName)
          ? numeral(changedField.newValue).format("0[.][0000000000000]")
          : label(String(changedField.newValue), { capitalize: true })}
      </div>
    </div>
  );
};

export default ActivityChangedField;
