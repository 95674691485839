import { useMutation, useQuery } from "@apollo/client";
import { range } from "lodash";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  AssessmentQuestionType,
  GetAssessmentQuestionQuery,
  GetAssessmentQuestionQueryVariables,
  SaveAssessmentQuestionMutation,
  SaveAssessmentQuestionMutationVariables,
} from "types/graphql-schema";

import { currentOrganizationVar, successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import createOrUpdateQuestionMutation from "../graphql/create-or-update-question-mutation";
import getQuestionQuery from "../graphql/get-question-query";
import {
  bgClassName,
  emptyAssessmentQuestion,
  getAssessmentQuestionScale,
  getValidAssessmentQuestionMessage,
} from "../helpers";
import AssessmentQuestionForm, {
  AssessmentQuestion,
} from "./assessment-question-form";

const AssessmentQuestionEdit: React.FC = () => {
  const link = useLink();
  const location = useLocation();
  const { questionId: questionIdParam } = useParams<{ questionId: string }>();
  const questionId = parseInt(questionIdParam);
  const isNew = isNaN(questionId);
  const isDuplicate = !isNew && location.pathname.includes("/duplicate");
  const currentOrganization = currentOrganizationVar();
  const [proposedQuestion, setProposedQuestion] = useState<AssessmentQuestion>(
    emptyAssessmentQuestion(AssessmentQuestionType.Range)
  );
  const { data: questionData, loading } = useQuery<
    GetAssessmentQuestionQuery,
    GetAssessmentQuestionQueryVariables
  >(getQuestionQuery, {
    variables: { questionId },
    skip: isNew,
    onCompleted: (response) => {
      const question = assertNonNull(response.assessmentQuestion);
      const categories = assertEdgesNonNull(question.categories);
      const isRangeQuestion =
        question.__typename === "RangeAssessmentQuestionNode";
      const isMultiChoiceQuestion =
        question.__typename === "MultiChoiceAssessmentQuestionNode";
      setProposedQuestion({
        title: question.title,
        description: question.description,
        startValue: isRangeQuestion ? question.startValue : undefined,
        endValue: isRangeQuestion ? question.endValue : undefined,
        labels: isRangeQuestion ? [...question.labels] : undefined,
        labelDescriptions: isRangeQuestion
          ? [...question.labelDescriptions]
          : undefined,
        optionCount: isMultiChoiceQuestion
          ? question.options.length
          : undefined,
        options: isMultiChoiceQuestion ? [...question.options] : undefined,
        optionDescriptions: isMultiChoiceQuestion
          ? [...question.optionDescriptions]
          : undefined,
        assessmentType: question.assessmentType,
        questionType: question.questionType,
        isCommentMandatory: question.isCommentMandatory,
        responses: question.responses,
        responseVisibility: question.responseVisibility,
        categories,
      });
    },
    onError: onNotificationErrorHandler(),
  });

  const canUpdateMessage =
    getValidAssessmentQuestionMessage(proposedQuestion) ??
    (!isDuplicate &&
    questionData &&
    !questionData.assessmentQuestion?.canUpdate?.permission
      ? questionData.assessmentQuestion?.canUpdate?.reason
      : null);
  const canSubmit = !canUpdateMessage;
  const scale = getAssessmentQuestionScale(proposedQuestion);

  const [createOrUpdateQuestion, { loading: loadingSave }] = useMutation<
    SaveAssessmentQuestionMutation,
    SaveAssessmentQuestionMutationVariables
  >(createOrUpdateQuestionMutation);

  const handleSaveForm = () => {
    const labels = range(0, scale.scaleDiff).map((x) => {
      return proposedQuestion.labels?.[x] || "";
    });
    const labelDescriptions = range(0, scale.scaleDiff).map((x) => {
      return proposedQuestion.labelDescriptions?.[x] || "";
    });
    const options = range(0, proposedQuestion.optionCount).map((x) => {
      return proposedQuestion.options?.[x] || "";
    });
    const optionDescriptions = range(0, proposedQuestion.optionCount).map(
      (x) => {
        return proposedQuestion.optionDescriptions?.[x] || "";
      }
    );
    createOrUpdateQuestion({
      variables: {
        title: proposedQuestion.title,
        description: proposedQuestion.description,
        startValue: proposedQuestion.startValue,
        endValue: proposedQuestion.endValue,
        isCommentMandatory: proposedQuestion.isCommentMandatory,
        responses: proposedQuestion.responses,
        responseVisibility: proposedQuestion.responseVisibility,
        labels,
        labelDescriptions,
        options,
        optionDescriptions,
        organizationId: currentOrganization?.id,
        questionId: isNew || isDuplicate ? undefined : questionId,
        assessmentType: proposedQuestion.assessmentType,
        questionType: proposedQuestion.questionType,
        categories: proposedQuestion.categories.map(({ title }) => title),
      },
      onCompleted: () => {
        successNotificationVar({ title: "Question saved" });
        link.redirect(`/assessments/assessment-questions`);
      },
      onError: onNotificationErrorHandler(),
    });
  };

  return (
    <div
      className={classNames(bgClassName, "p-6 w-full flex flex-col gap-6")}
      aria-label="Assessment question form"
    >
      <div className="flex items-center justify-between">
        <div className="text-xl font-medium">
          {isNew ? "Create" : isDuplicate ? "Duplicate" : "Edit"} assessment
          question
        </div>
        <div className="flex justify-end items-center gap-2 sm:gap-4">
          {loadingSave && <Loading mini size="4" />}
          <Button
            theme={buttonTheme.text}
            disabled={loadingSave}
            text="Discard changes"
            to="/assessments/assessment-questions"
          />
          <Button
            text="Save question"
            type="submit"
            theme={buttonTheme.primary}
            disabled={loadingSave || !canSubmit}
            onClick={handleSaveForm}
            tooltip={canUpdateMessage}
          />
        </div>
      </div>
      {loading ? (
        <Loading>Loading question</Loading>
      ) : (
        <AssessmentQuestionForm
          canUpdate={
            isNew ||
            isDuplicate ||
            !!questionData?.assessmentQuestion?.canUpdate?.permission
          }
          isNew={isNew || isDuplicate}
          answersExist={questionData?.assessmentQuestion?.answersExist}
          proposedQuestion={proposedQuestion}
          onChangeQuestion={setProposedQuestion}
        />
      )}
    </div>
  );
};

export default AssessmentQuestionEdit;
