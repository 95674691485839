import { Menu } from "@szhsin/react-menu";
import { compact } from "lodash";
import { ArtifactType } from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";

import { ExplorerFilterType, ExplorerTypeType } from "../helpers";
import ExplorerMenuButton from "./explorer-menu-button";
import ExplorerMenuOptions from "./explorer-menu-options";

const ExplorerSortOptions = ({
  filters,
  onChangeFilters,
}: {
  filters: ExplorerFilterType;
  onChangeFilters: (filters: ExplorerFilterType) => void;
}) => {
  const label = useLabel();
  const artifactTypesWithDueDateOptions = [
    ArtifactType.ActionItem,
    ArtifactType.Goal,
  ] as ExplorerTypeType[];
  const artifactTypesWithCompletedDateOptions = [
    ArtifactType.ActionItem,
  ] as ExplorerTypeType[];
  const orderByOptions = compact([
    {
      value: "created",
      filters: { orderBy: "created" },
      label: "Oldest",
    },
    {
      value: "-created",
      filters: { orderBy: "-created" },
      label: "Newest",
    },
    filters.type &&
      artifactTypesWithDueDateOptions.includes(filters.type) && {
        value: "due_date",
        filters: { orderBy: "due_date" },
        label: "Due date",
        description: `${label("goal", {
          pluralize: true,
          capitalize: true,
        })} & action items only`,
      },
    filters.type &&
      artifactTypesWithDueDateOptions.includes(filters.type) && {
        value: "-due_date",
        filters: { orderBy: "-due_date" },
        label: "Last due",
        description: `${label("goal", {
          pluralize: true,
          capitalize: true,
        })} & action items only`,
      },
    filters.type &&
      artifactTypesWithCompletedDateOptions.includes(filters.type) && {
        value: "completed_at",
        filters: { orderBy: "completed_at" },
        label: "Completed date",
        description: `Completed action items only`,
      },
    filters.type &&
      artifactTypesWithCompletedDateOptions.includes(filters.type) && {
        value: "-completed_at",
        filters: { orderBy: "-completed_at" },
        label: "Last completed",
        description: `Completed action items only`,
      },
    {
      value: "updated",
      filters: { orderBy: "updated" },
      label: "Least recently updated",
    },
    {
      value: "-updated",
      filters: { orderBy: "-updated" },
      label: "Recently updated",
    },
  ]);

  // RENDER
  return (
    <Menu
      className="text-sm z-dropdown"
      portal
      menuButton={
        <ExplorerMenuButton
          options={orderByOptions}
          filters={filters}
          prefix="Sort"
          filterKey="orderBy"
        />
      }
    >
      <ExplorerMenuOptions
        options={orderByOptions}
        onSelectMenuItem={onChangeFilters}
      />
    </Menu>
  );
};

export default ExplorerSortOptions;
