import { compact, uniqueId } from "lodash";
import { MouseEvent } from "react";
import {
  ActionItemState,
  ExplorerResultFragmentFragment,
  PageInfo,
} from "types/graphql-schema";

import { currentOrganizationVar } from "@cache/cache";
import Loading from "@components/loading/loading";
import Tooltip from "@components/tooltip/tooltip";
import { goalStateLabel } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { getNodeUrl } from "@helpers/helpers";

import ExplorerIntegrationsNeedQuery from "./components/explorer-integrations-need-query";
import ExplorerResult from "./components/explorer-result";
import { ExplorerFilterType, getFlattenExplorerResult } from "./helpers";

export const getResultUniqueId = (result: ExplorerResultFragmentFragment) => {
  const flattenedResult = getFlattenExplorerResult(result);
  if (!flattenedResult.id) return uniqueId("explorer-content");
  return `${result.__typename}-${flattenedResult.id}-${getNodeUrl(
    flattenedResult
  )}`;
};

const ExplorerContent = ({
  className = "",
  resultClassName = "flex-1 p-1.5 lg:py-3 lg:px-4",
  containerClassName = "flex-1 flex flex-col divide-y rounded bg-white border",
  paginationClassName = "",
  groupClassName = "text-sm block py-1.5 px-1.5 lg:py-2 lg:px-4 text-gray-500",
  needSearchQueryForIntegrations,
  loadingFirstTime,
  loading,
  filters,
  results,
  pageInfo,
  artifactUrlPrefix = "",
  selectionActivated = false,
  selection = [],
  onClickMore,
  onClickResult,
  onSelectResult,
}: {
  className?: string;
  resultClassName?: string;
  containerClassName?: string;
  paginationClassName?: string;
  groupClassName?: string;
  needSearchQueryForIntegrations: boolean;
  loadingFirstTime: boolean;
  loading: boolean;
  filters: ExplorerFilterType;
  results: ExplorerResultFragmentFragment[];
  pageInfo: PageInfo;
  artifactUrlPrefix?: string;
  selectionActivated?: boolean;
  selection?: any[];
  onClickMore: () => void;
  onClickResult?: (result: any) => void;
  onSelectResult?: (result: any) => () => void;
}) => {
  const currentOrganization = currentOrganizationVar();
  const actionItemStateByValue = compact(
    currentOrganization.actionItemStates
  ).reduce((memo, actionItemState) => {
    const key = actionItemState.value;
    memo[key] = actionItemState;
    return memo;
  }, {} as Record<number, ActionItemState>);

  const handleClickResult =
    (result: ExplorerResultFragmentFragment) =>
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      if (selectionActivated && onSelectResult) {
        onSelectResult(result)();
      } else if (onClickResult) {
        onClickResult(getFlattenExplorerResult(result));
      }
    };

  const resultGroupsById = filters.groupBy
    ? results.reduce(
        (memo, result) => {
          const id =
            filters.groupBy === "actionItemState"
              ? result.specificArtifact.actionItemState
              : result.specificArtifact.state;
          if (!memo[id]) {
            const label =
              filters.groupBy === "actionItemState"
                ? actionItemStateByValue[id].label
                : goalStateLabel[id];
            memo[id] = { label, results: [] };
          }
          memo[id].results = memo[id].results.concat(result);
          return memo;
        },
        {} as Record<
          number,
          {
            label: string;
            results: ExplorerResultFragmentFragment[];
          }
        >
      )
    : {
        default: {
          label: null,
          results,
        },
      };
  const resultGroups = Object.values(resultGroupsById);

  // RENDER
  return (
    <div className={classNames(className)}>
      {needSearchQueryForIntegrations ? (
        <ExplorerIntegrationsNeedQuery
          filters={filters}
          className="text-gray-500 bg-white border rounded text-center py-4"
        />
      ) : loadingFirstTime ? (
        <Loading size="6" className="py-4 text-sm">
          Loading...
        </Loading>
      ) : results.length > 0 ? (
        <div className="flex flex-col gap-6 w-full">
          {resultGroups.map((group) => (
            <div className="flex-1 w-full" key={group.label}>
              <div className={containerClassName}>
                {group.label && (
                  <div
                    className={classNames(
                      "text-gray-500 text-sm font-medium",
                      groupClassName
                    )}
                  >
                    {group.label}
                  </div>
                )}
                {group.results.map((result: ExplorerResultFragmentFragment) => (
                  <div
                    key={getResultUniqueId(result)}
                    className={classNames(
                      "text-left flex",
                      (!!onClickResult || selectionActivated) &&
                        "relative hover:bg-blue-50",
                      selectionActivated &&
                        selection.includes(getResultUniqueId(result)) &&
                        "bg-blue-50"
                    )}
                  >
                    {selectionActivated && onSelectResult && (
                      <Tooltip text="Select">
                        <label className="px-2 py-1 border-r flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            className="cursor-pointer"
                            checked={selection.includes(
                              getResultUniqueId(result)
                            )}
                            onChange={onSelectResult(result)}
                          />
                        </label>
                      </Tooltip>
                    )}
                    {(selectionActivated || onClickResult) && (
                      <button
                        onClick={handleClickResult(result)}
                        className="absolute inset-0 z-1"
                      />
                    )}
                    <div className={classNames(resultClassName)}>
                      <ExplorerResult
                        artifactUrlPrefix={artifactUrlPrefix}
                        result={result}
                        hideActionDropdown={!!onClickResult}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-gray-500 bg-white border rounded text-center py-4 text-sm">
          No results...
        </div>
      )}
      {pageInfo?.hasNextPage && (
        <div
          className={classNames(
            "flex items-center justify-center py-2",
            selectionActivated && "ml-8",
            paginationClassName
          )}
        >
          <button
            className="text-gray-500 text-sm mr-4 hover:underline px-4"
            onClick={onClickMore}
            disabled={loading}
          >
            Load more...
          </button>
          {loading && <Loading size="5" mini />}
        </div>
      )}
    </div>
  );
};

export default ExplorerContent;
