import { useLazyQuery } from "@apollo/client";
import { useCallback } from "react";

import getChatSessionMessagesQuery from "@apps/meeting/graphql/get-chat-session-messages-query";
import cache from "@cache/cache";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import usePusher from "@components/use-pusher/use-pusher";

export default function PusherChatSession({
  channelName,
}: {
  channelName: string;
}) {
  // Queries & Mutations
  const [fetchChatSession] = useLazyQuery(getChatSessionMessagesQuery, {
    onError: onNotificationErrorHandler(),
  });

  const handleNewChatMessage = useCallback(
    ({
      chat_session_id,
      new_message,
    }: {
      chat_session_id: number | string;
      new_message: any;
    }) => {
      const chatSessionCacheId = cache.identify({
        __typename: "ChatSessionNode",
        id: chat_session_id,
      });
      if (chatSessionCacheId) {
        cache.updateQuery(
          {
            query: getChatSessionMessagesQuery,
            variables: { chatSessionId: Number(chat_session_id) },
          },
          (cachedData) => {
            const chatSession = cachedData?.chatSession || {
              messages: {
                edges: [],
              },
            };
            return {
              ...cachedData,
              chatSession: {
                ...chatSession,
                messages: {
                  ...chatSession.messages,
                  edges: [
                    ...chatSession.messages.edges,
                    {
                      __typename: "ChatMessageEdge",
                      node: {
                        ...new_message,
                        autogenerated: null,
                        __typename: "ChatMessageNode",
                      },
                    },
                  ],
                },
              },
            };
          }
        );
        fetchChatSession({
          variables: { chatSessionId: parseInt(chat_session_id) },
        });
      }
    },
    [fetchChatSession]
  );

  usePusher(channelName, "new_chat_message", handleNewChatMessage);

  return null;
}
