import { gql } from "@apollo/client";

export default gql`
  mutation createOrUpdateFlowMutation(
    $title: String
    $public: Boolean
    $flowId: Int
    $organizationId: Int
    $meetingGroupIds: [Int]
    $participantEmailPatterns: String
  ) {
    createOrUpdateFlow(
      title: $title
      public: $public
      flowId: $flowId
      organizationId: $organizationId
      meetingGroupIds: $meetingGroupIds
      participantEmailPatterns: $participantEmailPatterns
    ) {
      flow {
        id
        title
        meetingGroups {
          edges {
            node {
              id
              title
            }
          }
        }
      }
    }
  }
`;
